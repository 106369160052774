import { element } from "prop-types";
import {
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  TOGGLE_MODAL,
  ONLY_TOGGLE_MODAL,
  ADD_QUANTITY,
  REMOVE_QUANTITY,
  CHANGE_QUANTITY,
  ADD_TO_CART,
  GET_CART,
  REMOVE_FROM_CART,
  REMOVE_EXTRA_FROM_CART,
  TOGGLE_DELIVERY_MODAL,
  CLOSE_MODAL,
  CLEAR_STORAGE,
  CHANGE_INSTRUCTION,
  GET_CATEGORIES_BEGIN,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  REMOVE_TABLE_CART_ITEM,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_ExtraItem_BEGIN,
  GET_ExtraItem_SUCCESS,
  GET_ExtraItem_FAILURE,
  ADD_PRICE,
  REMOVE_PRICE,
  REDUCE_EXTRA_ITEM,
  ADD_EXTRA_ITEM,
  ADD_TO_TABLE_CART,
  REMOVE_FROM_TABLE_CART,
  RemoveTableFromCart,
  EXTRA_INSTRUCTION
} from "../actions/index";

const initialState = {
  items: [],
  cart: [],
  loading: false,
  modal: false,
  isOpen: false,
  popup: false,
  error: null,
  quantity: 1,
  price: 0,
  activeTab: 1,
  totalQuantity: 0,
  totalExtraItemPrice: 0,
  totalPrice: 0,
  editingProduct: "",
  orderType: "",
  instruction: "",
  instructionIds: [],
  getCategoriesLoading: false,
  getCategoriesSuccess: false,
  getCategoriesError: false,
  categoriesData: [],
  getProductsLoading: false,
  getProductsSuccess: false,
  getProductsError: false,
  getExtraItemLoading: false,
  getExtraItemSuccess: false,
  getExtraItemError: false,
  productsData: [],
  extra: [],
  extraPrice: 0,
  extraItems: [],
  extraInstructions: ""
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.products
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        items: []
      };
    case GET_ExtraItem_BEGIN:
      return {
        ...state,
        getExtraItemLoading: true,
        getExtraItemSuccess: false,
        getExtraItemError: false,
        extra: []
      };
    case GET_ExtraItem_SUCCESS:
      return {
        ...state,
        getExtraItemLoading: false,
        getExtraItemSuccess: true,
        getExtraItemError: false,
        extra: action.payload.products
      };
    case GET_ExtraItem_FAILURE:
      return {
        ...state,
        getExtraItemLoading: false,
        getExtraItemSuccess: false,
        getExtraItemError: true,
        error: action.payload.error,
        extra: []
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        modal: !state.modal,
        editingProduct: action.payload,
        quantity: 1,
        instruction: "",
        instructionIds: [],
        extraItems: [],
        extraPrice: 0,
        extraInstructions: ""
      };
    case ONLY_TOGGLE_MODAL:
      return {
        ...state,
        modal: !state.modal
      };
    case CLOSE_MODAL:
      return { ...state, modal: false, isOpen: false, popup: false };
    case ADD_QUANTITY:
      return {
        ...state,
        quantity: state.quantity + 1
      };
    case REMOVE_QUANTITY:
      return {
        ...state,
        quantity: state.quantity >= 1 ? state.quantity - 1 : 0
      };
    case CHANGE_QUANTITY:
      return {
        ...state,
        quantity: action.value
      };
    case REMOVE_PRICE: {
      return {
        ...state,
        extraPrice: state.extraPrice - action.payload
      };
    }
    case ADD_PRICE: {
      return {
        ...state,
        extraPrice:
          action.payload > 0
            ? state.extraPrice + action.payload
            : state.extraPrice
      };
    }
    case REDUCE_EXTRA_ITEM: {
      let extraItems = [...state.extraItems];
      const arr = extraItems.filter((val) => {
        return action.payload._id != val._id;
      });
      return {
        ...state,
        extraItems: arr
      };
    }
    case ADD_EXTRA_ITEM: {
      let extraItems = [...state.extraItems];
      if (action.payload) {
        extraItems.push(action.payload);
      }
      return {
        ...state,
        extraItems: extraItems
      };
    }
    case ADD_TO_CART:
      let {
        cart,
        editingProduct,
        quantity,
        modal,
        totalPrice,
        totalQuantity,
        instruction,
        extraInstructions,
        instructionIds,
        extraPrice,
        extraItems,
        totalExtraItemPrice
      } = state;

      // Checks if the product is already in cart and increases it's quantity
      for (var i = 0; i < cart.length; i++) {
        if (cart[i]._id === editingProduct._id) {
          cart[i].quantity += quantity;
          cart[i].extraPrice += extraPrice;
          cart[i].totalPrice = cart[i].quantity * cart[i].price;
          cart[i].totalEXPrice =
            cart[i].quantity * cart[i].price + cart[i].extraPrice;
          let inst = {
            quantity: quantity,
            instruction: instruction,
            instructionIds: instructionIds,
            productName: editingProduct.name,
            extraInstructions: extraInstructions
          };
          let extTt = {
            extraPrice: extraPrice,
            items: extraItems
          };
          cart[i].extraItems.push(extraItems);
          cart[i].instruction.push(inst);
          // cart[i].extraInstructions.push(extraInstructions)
          const totalPArr = cart.reduce((acc, item) => {
            return acc + item.totalEXPrice;
          }, 0);
          let totalQ = totalQuantity + quantity;
          let totalEP = Number(totalExtraItemPrice + extraPrice);
          return {
            ...state,
            cart: [...cart],
            totalPrice: totalPArr,
            totalQuantity: totalQ,
            totalExtraItemPrice: totalEP,
            modal: !modal
          };
        }
      }

      // If the product is not in the cart, goes here
      editingProduct.quantity = Number(quantity);
      editingProduct.totalPrice = quantity * editingProduct.price;
      editingProduct.totalEXPrice =
        quantity * editingProduct.price + extraPrice;
      let inst = {
        quantity: quantity,
        instruction: instruction,
        instructionIds: instructionIds,
        productName: editingProduct.name,
        extraInstructions: extraInstructions ? extraInstructions : ""
      };
      let extTt = {
        extraPrice: extraPrice,
        items: extraItems
      };

      editingProduct.instruction = [];
      // editingProduct.instructionIds = [];
      editingProduct.extraItems = [];
      editingProduct.extraPrice = extraPrice;
      editingProduct.instruction.push(inst);
      editingProduct.extraItems.push(extraItems);
      totalPrice += editingProduct.totalEXPrice;
      totalQuantity += Number(editingProduct.quantity);
      totalExtraItemPrice += Number(extraPrice);
      return {
        ...state,
        modal: !modal,
        totalPrice,
        totalQuantity,
        totalExtraItemPrice,
        cart: [...cart, editingProduct]
      };
    case GET_CART:
      return {
        ...state
        // cart: [...action.cart],
        // totalPrice: action.totalPrice,
        // totalQuantity: action.totalQuantity
      };
    case REMOVE_FROM_CART:
      let newQuantity;
      let newPrice;
      let newTotalExtraItemPrice;
      const newCart = state.cart.filter((item) => {
        if (item._id === action.id) {
          const quantity = item.quantity;
          const totalPrice = item.totalEXPrice;
          const totalExtaItmPrice = item.extraPrice;
          const oldQuantity = state.totalQuantity;
          const oldPrice = state.totalPrice;
          const oldTotalExtaItmPrice = state.totalExtraItemPrice;
          newQuantity = Number(oldQuantity) - Number(quantity);
          newPrice = Number(oldPrice) - Number(totalPrice);
          newTotalExtraItemPrice =
            Number(oldTotalExtaItmPrice) - Number(totalExtaItmPrice);
        }
        return item._id !== action.id;
      });
      return {
        ...state,
        cart: [...newCart],
        totalQuantity: newQuantity,
        totalPrice: newPrice,
        totalExtraItemPrice: newTotalExtraItemPrice
      };
    case REMOVE_EXTRA_FROM_CART:
      let nPrice;
      let nTotalExtraItemPrice;
      const { extraId, proId, ix } = action.ids;
      let nCart = [];
      for (const [idx, ele] of state.cart.entries()) {
        if (ele._id === proId) {
          let extItems = ele.extraItems;
          let extraPrice = ele.extraPrice;
          let totalEXPrice = ele.totalEXPrice;
          ele.extraItems = [];
          for (let i = 0; i < extItems.length; i++) {
            const arr = extItems[i].filter((item) => {
              if (item._id === extraId) {
                nPrice = Number(state.totalPrice - item.price);
                totalExtraItemPrice = Number(
                  state.totalExtraItemPrice - item.price
                );
                extraPrice = Number(ele.extraPrice - item.price);
                totalEXPrice = Number(ele.totalEXPrice - item.price);
              }
              let flag = true;
              if (item._id === extraId && ix === i) {
                flag = false;
              }
              return flag;
            });
            ele.extraItems.push(arr);
            ele.extraPrice = extraPrice;
            ele.totalEXPrice = totalEXPrice;
          }
        }
        nCart.push(ele);
      }
      return {
        ...state,
        cart: [...nCart],
        totalPrice: nPrice,
        totalExtraItemPrice: nTotalExtraItemPrice
      };
    case TOGGLE_DELIVERY_MODAL:
      return {
        ...state,
        popup: !state.popup,
        orderType: action.orderType
      };
    case CLEAR_STORAGE:
      return {
        ...initialState
      };
    case CHANGE_INSTRUCTION:
      const { desc, ids } = action.value;
      let instdec = desc;
      if (desc === undefined) {
        instdec = action.value;
      }
      return {
        ...state,
        instruction: instdec,
        instructionIds: ids ? ids : state.instructionIds
      };
    case EXTRA_INSTRUCTION:
      console.log("extraaaaa", action.value);

      return {
        ...state,
        extraInstructions: action.value
      };
    case GET_CATEGORIES_BEGIN:
      return {
        ...state,
        getCategoriesLoading: true,
        getCategoriesSuccess: false,
        getCategoriesError: false,
        categoriesData: []
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        getCategoriesLoading: false,
        getCategoriesSuccess: true,
        getCategoriesError: false,
        categoriesData: action.data.result
      };
    case GET_CATEGORIES_ERROR:
      return {
        ...state,
        getCategoriesLoading: false,
        getCategoriesSuccess: false,
        getCategoriesError: true,
        categoriesData: []
      };
    case GET_PRODUCTS_BEGIN:
      return {
        ...state,
        getProductsLoading: true,
        getProductsSuccess: false,
        getProductsError: false,
        productsData: []
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        getProductsLoading: false,
        getProductsSuccess: true,
        getProductsError: false,
        productsData: action.data.result
      };
    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        getProductsLoading: false,
        getProductsSuccess: false,
        getProductsError: true,
        productsData: []
      };

    case ADD_TO_TABLE_CART:
      const cartArray = state.cart;
      let newCarArray = [...cartArray];
      let newtotalQuantity = state.totalQuantity;
      let newtotalPrice = state.totalPrice;

      console.log(action.payload._id);
      const findCart = newCarArray.findIndex((sinCart) => {
        console.log(sinCart._id);
        return sinCart._id == action.payload._id;
      });
      console.log(findCart);

      if (findCart >= 0) {
        newCarArray[findCart].quantity = newCarArray[findCart].quantity + 1;
        newCarArray[findCart].totalPrice =
          newCarArray[findCart].totalPrice + newCarArray[findCart].price;
        newtotalQuantity = Number(newtotalQuantity) + 1;
        newtotalPrice =
          Number(newtotalPrice) + Number(newCarArray[findCart].price);
      }

      return {
        ...state,
        cart: newCarArray,
        totalQuantity: newtotalQuantity,
        totalPrice: newtotalPrice
      };
    case REMOVE_FROM_TABLE_CART:
      const pcartArray = state.cart;
      let removeCartArray = [...pcartArray];
      let newremovedtotalQuantity = state.totalQuantity;

      let newremovedtotalPrice = state.totalPrice;

      const findCartIndex = removeCartArray.findIndex((sinCart) => {
        // console.log(sinCart._id);
        return sinCart._id == action.payload._id;
      });
      // console.log(findCartIndex);

      if (findCartIndex >= 0) {
        if (removeCartArray[findCartIndex].quantity == 1) {
          return {
            ...state
          };
        }
        removeCartArray[findCartIndex].quantity =
          removeCartArray[findCartIndex].quantity - 1;
        removeCartArray[findCartIndex].totalPrice =
          removeCartArray[findCartIndex].totalPrice -
          removeCartArray[findCartIndex].price;
        newremovedtotalQuantity = Number(newremovedtotalQuantity) - 1;
        newremovedtotalPrice =
          Number(newremovedtotalPrice) -
          Number(removeCartArray[findCartIndex].price);
      }

      return {
        ...state,
        cart: removeCartArray,
        totalQuantity: newremovedtotalQuantity,
        totalPrice: newremovedtotalPrice
      };

    case REMOVE_TABLE_CART_ITEM:
      let removeCartItemArray = state.cart;
      let rcartArray = [...removeCartItemArray];
      if (action.payload.quantity == 0) {
        return {
          ...state
        };
      }

      let cartItemTotalPrice =
        state.totalPrice - action.payload.quantity * action.payload.price;
      let cartItemQuantity = state.totalQuantity - action.payload.quantity;
      rcartArray = rcartArray.filter((sinCat) => {
        return sinCat._id != action.payload._id;
      });
      return {
        ...state,
        cart: rcartArray,
        totalQuantity: cartItemQuantity,
        totalPrice: cartItemTotalPrice
      };
    default:
      return state;
  }
};

export default products;
