import React, { Component } from "react";

import { Route, Switch } from "react-router-dom";
import * as io from "socket.io-client";
import PickupForm from "./PickupForm";
import OTP from "./OTP";
import TreeDriveOtp from "./TreeDriveOtp";
import TableOrderConfirm from "./TableOrderConfirmation";
import globalConst from "../helpers/global";
import {socket} from '../helpers/globSocket'
import VippsOtp from "./VippsOtp";
import TableOrderConfirmVipps from "./TableOrderConfirmVipps";
class Pickup extends Component {
  constructor(props) {
    super(props);
 
  }
  componentDidMount() {
    // const socket = io(`${globalConst.serverip}`, {
    //   transports: ["websocket"]
    // });

    socket.on("connect", () => {
      socket.on("transactionsuccess", (data) => {
       
        alert("SUCCESS FINAL ");
      });
    });
  }
  render() {
    return (
      <>
        <Switch>
          <Route exact path="/checkout/pickup" component={PickupForm} />
          <Route exact path="/checkout/pickup/otp" component={OTP} />
          <Route exact path="/checkout/pickup/treedrive/otp" component={TreeDriveOtp} />
          <Route exact path="/checkout/pickup/vipps/:id" component={VippsOtp} />
          <Route
            exact
            path="/checkout/pickup/tableorderconfirmation"
            component={TableOrderConfirm}
          />
          <Route
            exact
            path="/checkout/pickup/tableorderconfirmation/vipps/:id"
            component={TableOrderConfirmVipps}
          />

          
        </Switch>
      </>
    );
  }
}

export default Pickup;
