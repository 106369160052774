import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { PlusOutlined } from "@ant-design/icons";
import { BiPlus } from "react-icons/bi";
import { Button, Modal, Checkbox } from "antd";
import { Col, Container, Row, ModalHeader } from "reactstrap";
import CheesePizzaImg from "../assets/cheese-pizza.jpg";
import globalConst from "../helpers/global";
import { useSelector } from "react-redux";
import { Collapse } from "antd";
import uuid from "react-uuid";
const { Panel } = Collapse;
const NewOrderNowCom = (props) => {
  // console.log("props", props);
  const extraItems = useSelector((state) => state.products.extra);
  // console.log("myState", extraItems);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [spiciness, setSpiciness] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [instruction, setInstructions] = useState("");
  const [ingredientsNotRequired, setIngredientsNotRequired] = useState([]);
  const [extraItemsArray, setExtraItemsArray] = useState([]);
  const [price, setPrice] = useState(props.product.price);
  const [instructionIds, setInstructionIds] = useState([]);
  const [ingsChecked, setIngsChecked] = useState([]);
  useEffect(() => {
      setPrice(props.product.price * quantity);
  }, [quantity]);

useEffect(()=>{
  // console.log("----------PROPS------",props)
 let ingds=props.product.ingredents?props.product.ingredents:[]

 let checkArr=[]
  ingds.map((a)=>{
    checkArr.push(true)
  })

  setIngsChecked(checkArr)
//  console.log("------a---",aa)


},[])
  const onChange = (key) => {
    console.log(key);
  };
  const addToCartClicked = () => {
    const extraPrice = extraItemsArray.reduce((acc, item1) => {
      acc = acc + Number(item1.price);
      return acc;
    }, 0);

    let myInstruction = [];
    if (instructionIds.length > 0) {
      myInstruction = [{ quantity: quantity, instructionIds: instructionIds }];
    }

    console.log("hello");
    const uId = uuid();
    const itemsToSaveInCart = {
      _uniqueId: uId,
      _id: props.product._id,
      name: props.product.name,
      spiciness: spiciness,
      ingredientsNotRequired: ingredientsNotRequired,
      instructions: instruction,
      instruction: myInstruction,

      quantity: quantity,
      totalPrice: price,
      price: props.product.price,
      extraItems: extraItemsArray,
      itemNo: props.product.itemNo,
      thumbnail: props.product.thumbnail,
      image: props.product.image,
      extraPrice: extraPrice,
      qtyTrack: [],
      ingredents: props.product.ingredents,
      totalEXPrice: Number(price) + Number(extraPrice),
      superCategory: props.superCategory,
      categoryId: props.product.categoryId
    };
    console.log("itemsToSaveInCart", itemsToSaveInCart);
    props.onOk(itemsToSaveInCart);
  };
  const onChangeChecked = (e, name, price, id) => {
    if (e.target.checked) {
      console.log(e);
      setExtraItemsArray([...extraItemsArray, { name, price, _id: id }]);
    } else {
      const filterdArray = getIndexAndFilterItems(extraItemsArray, name);
      setExtraItemsArray(filterdArray);
    }
  };

  const getIndexAndFilterItems = (arr, val) => {
    const myIndex = arr.findIndex((item) => {
      return item.name || item == val;
    });
    if (myIndex > -1) {
      const newArray = arr.filter((item, index) => {
        return index != myIndex;
      });
      return newArray;
    }
  };
  return (
    <Modal
      style={{ top: 10 }}
      visible={props.isVisible}
      // onOk={()=>props.onOk()}
      confirmLoading={confirmLoading}
      onCancel={() => {
        props.onCancel();
      }}
      footer={[
        <Button
          key="link"
          type="primary"
          // loading={loading}
          onClick={addToCartClicked}
          className="btn-success"
        >
          Add to cart
        </Button>
      ]}
    >
      <Container style={{ padding: "0px" }}>
        <ModalHeader className="product_Modal">
          {/* {props.product.name}

                  <br />
                  <span style={{ color: "grey", fontSize: "15px" }}>
                    Pris :  {`Kr ${price}`}
                  </span> */}
        </ModalHeader>
        <Row className="py-4">
          <Col className="text-center" fluid="sm">
            <img
              src={`${globalConst.s3url}${props.product.image}`}
              width="120px"
              height="100px"
            />
          </Col>
          <div style={{ width: "50%" }}>
            <Col className=" productContainer " fluid="sm">
              <Col className="product_Name">{props.product.name}</Col>
              <Col className=" product_Price" fluid="sm">
                {`Kr. ${price}`}
              </Col>
            </Col>
            <br />

            <div>
              <Button
                className="minusBtn"
                onClick={() => {
                  if (quantity > 1) {
                    setQuantity(quantity - 1);
                  }
                }}
              >
                -
              </Button>
              <span style={{ marginLeft: "5px", marginRight: 5 }}>
                {quantity}
              </span>
              <Button
                className="plusBtn"
                onClick={() => {
                  setQuantity(quantity + 1);
                }}
              >
                +
              </Button>
            </div>
          </div>
          {/* </Col> */}
          {/* <Col className="bg-light" fluid="sm">
            {`Kr ${price}`}
          </Col> */}
        </Row>
      </Container>
      <Container>
        <Row className="py-2">
          <Col className="bg-light" fluid="sm">
            <p
              style={{
                color: "rgba(0, 0, 0, 0.85)",
                fontSize: "20px",
                fontWeight: "500"
              }}
            >
              {" "}
              {props.product.isExtraInformation ? "Spiciness" : ""}
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              {props.product.isExtraInformation &&
                ["Mild", "Spicy", "ExtraSpicy"].map((value) => {
                  return (
                    <React.Fragment key={value}>
                      <input
                        key={value}
                        type={"checkbox"}
                        multiple={false}
                        name="spicessness"
                        value={value}
                        checked={spiciness == value}
                        onChange={() => setSpiciness(value)}
                      />
                      <label style={{ marginBottom: "0px" }}>{value}</label>
                    </React.Fragment>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="py-2">
          <Col className="bg-light" fluid="sm">
            {props && props.product && props.product.ingredents.length > 0 && (
              <p
                style={{
                  color: "rgba(0, 0, 0, 0.85)",
                  fontSize: "20px",
                  fontWeight: "500"
                }}
              >
                Ingredienser
              </p>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
              }}
            >
              {props &&
                props.product &&
                props.product.ingredents.length > 0 &&
                props.product.ingredents.map(({ _id, stockproduct }, index) => {
                  return (
                    <React.Fragment key={_id}>
                      <div
                        style={{
                          // width: "25%",
                          fontSize: "12px",
                          fontWeight: "500px"
                          // margin: "10px 0",
                          // textTransform: "uppercase"
                        }}
                      >
                        <input
                          key={index}
                          type={"checkbox"}
                          name={`ingredients+${index}`}
                          value={stockproduct && stockproduct.name}
                          checked={ingsChecked[index]}
                          onChange={(e) => {
                            console.log("eee", e);
                            const eventVar = e.target.checked;
                            // console.log("event var------------------------",eventVar);
                            // console.log("index---",index);
                            let unChecked=ingsChecked

                              unChecked[index]=e.target.checked
                              setIngsChecked(unChecked)
                            if (!eventVar) {
                              setInstructionIds([...instructionIds, _id]);

                              setIngredientsNotRequired((prev) => {
                                return [...prev, e.target.defaultValue];
                              });
                              // console.log('----------------instruction states',{
                              //   ingredientsNotRequired,instructionIds,
                              // })
                            } else {
                              const filteredArr = getIndexAndFilterItems(
                                ingredientsNotRequired,
                                e.target.defaultValue
                              );
                              setIngredientsNotRequired(filteredArr);
                              const idsArray = getIndexAndFilterItems(
                                instructionIds,
                                _id
                              );
                              setInstructionIds(idsArray);
                            }
                          }}
                        />
                        <label style={{ margin: "0 10px" }}>
                          {stockproduct && stockproduct.name}
                        </label>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="py-2">
          <Col className="bg-light" fluid="sm">
            <div>
              <p
                style={{
                  color: "rgba(0, 0, 0, 0.85)",
                  fontSize: "20px",
                  fontWeight: "500"
                }}
              >
                Legg til kommentar (valgfritt):
              </p>

              <textarea
                className="instructions"
                id="story"
                name="story"
                rows="6"
                cols="60"
                value={instruction}
                onChange={(e) => setInstructions(e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ display: "block" }}>
          {extraItems.map((extraItem) => {
            // console.log("extra intems", extraItem);
            return (
              <Col fluid="sm" key={extraItem}>
                <Collapse
                  onChange={onChange}
                  style={{ marginBottom: "0.5rem" }}
                  expandIcon={({ isActive }) => (
                    <PlusOutlined style={{ verticalAlign: "2px" }} />
                  )}
                >
                  <Panel
                    header={extraItem.name}
                    key={extraItem.name}
                    showArrow="true"
                  >
                    {extraItem.items.map((item) => {
                      return (
                        <div className="accordionItems" key={extraItem.name}>
                          <Checkbox
                            style={{ marginRight: "8px" }}
                            onChange={(e) =>
                              onChangeChecked(
                                e,
                                item.name,
                                item.price,
                                item._id
                              )
                            }
                          />
                          <img
                            src={`${globalConst.s3url}${item.image}`}
                            width="35px"
                            height="35px"
                          />
                          <span style={{ marginRight: "10px" }}>
                            {` ${item.name} (Kr. ${item.price})`}{" "}
                          </span>
                        </div>
                      );
                    })}
                  </Panel>
                </Collapse>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Modal>
  );
};

export default NewOrderNowCom;
