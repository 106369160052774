import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import { connect } from "react-redux";
import queryString from "query-string";
import { clearStorage, treeDrive_order_transaction_check } from "../actions";
import remove from "../assets/remove.png";
import axios from "axios";
import globalConst from "../helpers/global";

// import { Component } from "spinners-react";

class TreeDriveOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_id: "",
      loading: false,
      paymentLoading: true
    };
  }

  componentDidMount() {
    console.log("inside the treedrive otp mount----->")
    var rr = this.useQuery();
    
    this.setState({
      orderId: rr
    });
   
    setTimeout(() => {
      if (rr) {
        this.setState({
          paymentLoading: false
        });
        this.orderCheck(rr);
        // this.props.dispatch(clearStorage());
      }
    }, 2000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.ordertransactionCheckSuccess !==
      this.props.ordertransactionCheckSuccess
    ) {
     
      
    }
  }
  useQuery() {
    var quey = queryString.parse(this.props.location.search);
    // this.setState({ transaction_id: quey.transaction_id });
    return quey.merchant_reference;
  }
  orderCheck(id) {
  
    var postBody = {
      id: id
    };

    let { dispatch } = this.props;
    dispatch(treeDrive_order_transaction_check(postBody));
  }

  // const OTP = ({ otp, dispatch, history, slug }) => {
  
  clearState = () => {
    this.props.dispatch(clearStorage());
    this.props.history.push(`/restaurant/${this.props.slug}`);
  };
  render() {
    console.log("inside the treedrive otp----->")
    return (
      <>
     { console.log("inside the treedrive otp----->")}
      <Card className="checkout-card">
        <CardHeader>Order Details</CardHeader>
        {this.props.ordertransactionCheckLoading ||
        this.state.paymentLoading ? (
          <h3 align="center">Loading..</h3>
        ) : (
          <CardBody className="otp-card">
            {this.props.ordertransactionCheckData &&
            this.props.ordertransactionCheckData.message == "UNAUTHORIZED" ? (
              <img src={remove} height={140} />
            ) : (
              <div className="checkmark-circle">
                <div className="background" />
                <div className="checkmark draw" />
              </div>
            )}

            {this.props.ordertransactionCheckData &&
            this.props.ordertransactionCheckData.message == "UNAUTHORIZED" ? (
              <div>
                <p>
                  Noen problemer oppstod.
                  <br />
                  Betaling ikke mottatt fra slutten
                </p>
                <Button
                  color="success"
                  outline
                  onClick={() => this.clearState()}
                >
                  Gå til hjemmesiden
                </Button>
                <Button color='success' outline onClick={() => {this.props.history.go(-2)}}>Prøv betaling på nytt</Button>

              </div>
            ) : (
              <div>
                <h1 className="display-4">
                  <strong>
                    OTP:{" "}
                    {this.props.ordertransactionCheckData.otp
                      ? this.props.ordertransactionCheckData.otp
                      : ""}
                  </strong>
                </h1>
                <p>
                  {/* You need to provide OTP at the restaurant to collect your order. */}
                  Du må oppgi OTP på restauranten for å hente bestillingen
                  <br />
                  {/* We have also send the OTP to your email address. */}
                  Vi har også sendt OTP til e-postadressen din
                </p>
                <Button
                  color="success"
                  outline
                  onClick={() => this.clearState()}
                >
                  {/* Place New Order */}
                  Legg inn ny ordre
                </Button>
               
              </div>
            )}
          </CardBody>
        )}
      </Card>
     
      </>

    );
  }
}

const mapStateToProps = ({ order, restaurant }) => {
  return {
    otp: order.otp,
    id: restaurant.id,
    slug: restaurant.slug,
    orderType: order.orderTypes,
    ordertransactionCheckLoading: order.ordertransactionCheckLoading,
    ordertransactionCheckSuccess: order.ordertransactionCheckSuccess,
    ordertransactionCheckError: order.ordertransactionCheckError,
    ordertransactionCheckData: order.ordertransactionCheckData
    // orderPaymentCheckLoading: order.orderPaymentCheckLoading,
    // orderPaymentCheckSuccess: order.orderPaymentCheckSuccess,
    // orderPaymentCheckData: order.orderPaymentCheckData
  };
};

export default withRouter(connect(mapStateToProps)(TreeDriveOtp));
