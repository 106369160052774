import { combineReducers } from "redux";
import products from "./productsReducer";
import order from "./orderReducer";
import restaurant from "./restaurantReducer";
import paymentConfirmReducer from "./paymentConfirmReducer";
import cartReducer from "./cartReducer";

export default combineReducers({
  products,
  order,
  restaurant,
  paymentConfirmReducer,
  cartReducer
});
