// import React from "react";
// import { Modal, Button } from "antd";

// class ModalCart extends React.Component {
//   state = {
//     loading: false,
//     visible: false
//   };

//   showModal = () => {

//     this.setState({
//       visible: true
//     });
//   };

//   handleOk = () => {
//     this.setState({ loading: true });

//   };

//   handleCancel = () => {

//     this.setState({ visible: false });

//   };

//   render() {
//    
//     const { visible, loading } = this.state;
//     return (
//       <>
//         <Button type="primary" onClick={this.showModal}>
//           Open Modal with customized footer
//         </Button>
//         <Modal
//           visible={visible}
//           title="Title"
//           onOk={this.handleOk}
//           onCancel={this.handleCancel}
//           footer={[
//             <Button key="back" onClick={this.handleCancel}>
//               Return
//             </Button>,
//             <Button
//               key="submit"
//               type="primary"
//               loading={loading}
//               onClick={this.handleOk}
//             >
//               Submit
//             </Button>,
//             <Button
//               key="link"
//               href="https://google.com"
//               type="primary"
//               loading={loading}
//               onClick={this.handleOk}
//             >
//               Search on Google
//             </Button>
//           ]}
//         >
//           <p>Some contents...</p>
//           <p>Some contents...</p>
//           <p>Some contents...</p>
//           <p>Some contents...</p>
//           <p>Some contents...</p>
//         </Modal>
//       </>
//     );
//   }
// }

// export default ModalCart;

import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Cart from "./../components/Cart";
function ModalCart({
  cart,
  totalPrice,
  totalQuantity,
  openDeliveryOptions,
  driveoutCharge,
  isTableOrder,
  taxes,
  removeItemFromCart,
  removeExtraFromCart,
  button = "Continue",
  showCartModal,
  showCartModalChange,
  handleModalClose
}) {
  return (
    <>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <Modal show={showCartModal} onHide={handleModalClose}>
        <Modal.Body>
        <Modal.Header closeButton className="modalheader">
        </Modal.Header>
          <Cart
            cart={cart}
            totalPrice={totalPrice}
            totalQuantity={totalQuantity}
            openDeliveryOptions={openDeliveryOptions}
            driveoutCharge={driveoutCharge}
            isTableOrder={false}
            taxes={taxes}
            removeItemFromCart={(id) => removeItemFromCart(id)}
            removeExtraFromCart={(proId, extraId, ix) =>
              removeExtraFromCart(proId, extraId, ix)
            }
            button="Continue"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" onClick={handleModalClose} style={{backgroundColor:"#1B2F44",color:"white"}}>
          Legg til flere produkter
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCart;
