import React, { Component } from "react";
import { Jumbotron, Container } from "reactstrap";
export default class MerchantTerm extends Component {
  render() {
    return (
      <div style={{ paddingBottom: 30 }}>
        <Container style={{ paddingTop: 20 }}>
          {/* <img
            style={{ alignSelf: "center" }}
            src={require("../assets/fabro.png")}
          ></img> */}
          <img
            style={{ alignSelf: "center", float: "right" }}
            src={require("../assets/omd.png")}
          ></img>
        </Container>
        <Jumbotron fluid>
          <Container fluid>
            <h1 style={{ textAlign: "center" }}>
              Standard salgsbetingelser for forbrukerkjøp av varer over
              Internett
            </h1>
            <p style={{ textAlign: "center" }}>
              Versjon 2.0 revidert oktober 2015. Oppdatert januar 2018.{" "}
            </p>
            {/* <p className="lead">This is a modified jumbotron that occupies the entire horizontal space of its parent.</p> */}
          </Container>
        </Jumbotron>

        <Container>
          <h3> Innledning:</h3>
          <p>
            Dette kjøpet er regulert av de nedenstående standard
            salgsbetingelser for forbrukerkjøp av varer over Internett.
            Forbrukerkjøp over internett reguleres hovedsakelig av avtaleloven,
            forbrukerkjøpsloven, markedsføringsloven, angrerettloven og
            handelsloven, og disse lovene gir forbrukeren ufravikelige
            rettigheter. Lovene er tilgjengelig på{" "}
            <a href="https://lovdata.no/" target="_blank">
              www.lovdata.no.
            </a>{" "}
            Vilkårene i denne avtalen skal ikke forstås som noen begrensning i
            de lovbestemte rettighetene, men oppstiller partenes viktigste
            rettigheter og plikter for handelen
          </p>
          <p>
            Salgsbetingelsene er utarbeidet og anbefalt av Forbrukertilsynet.
            For en bedre forståelse av disse salgsbetingelsene, se{" "}
            <a href="https://www.forbrukertilsynet.no/" target="_blank">
              Forbrukertilsynets veileder
            </a>
            .{" "}
          </p>
          <h3> 1] Avtalen :</h3>
          <p>
            Avtalen består av disse salgsbetingelsene, opp- lysninger gitt i
            bestillingsløsningen og eventuelt særskilt avtalte vilkår. Ved
            eventuell motstrid mellom opplysningene, går det som særskilt er av-
            talt mellom partene foran, så fremt det ikke strider mot ufravikelig
            lovgivning.{" "}
          </p>
          <p>
            Avtalen vil i tillegg bli utfylt av relevante lovbestemmelser som
            regulerer kjøp av varer mellom næringsdrivende og forbrukere.{" "}
          </p>
          <h3> 2] Partene :</h3>
          <p style={{ fontWeight: "bold" }}>Selger er </p>
          <p style={{ fontWeight: "bold" }}>Firma: OM DATA AS</p>
          <p style={{ fontWeight: "bold" }}>Plattform: www.web.a-board.world</p>
          <p style={{ fontWeight: "bold" }}>
            {" "}
            Adresse:
            <span style={{ color: "grey" }}>
              {" "}
              Rosenborgveien 3 1630 Gamle Fredrikstad
            </span>
          </p>
          <p>
            E-post: <a href="">info@omdata.world </a>
            <p>Tel: + 47 91638483 </p>
            <p>919419792 mva</p>
          </p>
          <p>og betegnes i det følgende som selger/selgeren. </p>
          <p>
            Kjøper er den forbrukeren som foretar bestillingen, og betegnes i
            det følgende som kjøper/kjøperen.{" "}
          </p>
          <h3>3] Pris </h3>
          <p>
            Den oppgitte prisen for varen og tjenester er den totale prisen
            kjøper skal betale. Denne prisen inkluderer alle avgifter og
            tilleggskostnader. Ytterligere kostnader som selger før kjøpet ikke
            har informert om, skal kjøper ikke bære.{" "}
          </p>
          <h3>4] Avtaleinngåelse </h3>
          <p>
            Avtalen er bindende for begge parter når kjøperen har sendt sin
            bestilling til selgeren.
          </p>
          <p>
            Avtalen er likevel ikke bindende hvis det har forekommet skrive
            eller tastefeil i tilbudet fra selgeren i bestillingsløsningen i
            nettbutikken eller i kjøperens bestilling, og den annen part inn så
            eller burde ha innsett at det forelå en slik feil.{" "}
          </p>
          <h3>Innholdsfortegnelse: </h3>
          <p style={{ color: "grey" }}>1] Avtalen</p>
          <p style={{ color: "grey" }}>2] Partene</p>
          <p style={{ color: "grey" }}>3] Pris</p>
          <p style={{ color: "grey" }}>4] Avtaleinngåelse </p>
          <p style={{ color: "grey" }}>5] Betaling </p>
          <p style={{ color: "grey" }}>6] Levering </p>
          <p style={{ color: "grey" }}>7] Risikoen for varen</p>
          <p style={{ color: "grey" }}>8] Angrerett</p>
          <p style={{ color: "grey" }}>9] Forsinkelse og manglende levering </p>
          <p style={{ color: "grey" }}>
            -kjøperens rettigheter og frist for å melde krav
          </p>
          <p style={{ color: "grey" }}>
            10] Mangel ved varen - kjøperens rettigheter og reklamasjonsfrist
          </p>
          <p style={{ color: "grey" }}>
            11] Selgerens rettigheter ved kjøperens mislighold
          </p>
          <p style={{ color: "grey" }}>12] Garanti </p>
          <p style={{ color: "grey" }}>13] Personopplysninger</p>
          <p style={{ color: "grey" }}>14] Konfliktløsning </p>
          <h3>5] Betaling </h3>
          <p>
            Selgeren kan kreve betaling for varen fra det tidspunkt den blir
            lagt inn i nettbestillingen og varen blir tilsendt fra selgeren til
            kjøperen samedag.
          </p>
          <p>
            Dersom kjøperen bruker kredittkort eller debetkort ved betaling, kan
            selgeren reservere kjøpesummen på kortet ved bestilling. Kortet blir
            belastet samme dag som varen sendes.{" "}
          </p>
          <p>
            Ved betaling med faktura, blir fakturaen til kjøperen utstedt ved
            forsendelse av varen. Betalingsfristen fremgår av fakturaen og er på
            minimum 7 dager fra mottak, dette tjenesten er kun for enkelte
            brukere og ikke er en standard tjeneste fra OM DATA AS.{" "}
          </p>
          <p>
            Kjøpere under 18 år kan ikke betale med etter- følgende faktura.{" "}
          </p>
          <h3>6] Levering </h3>
          <p>
            Levering er skjedd når kjøperen, eller hans representant, har
            overtatt varer (mat & drikke samt leveringskostnader).
            Leveringskostnader gjelder ikke ved selv henting.
          </p>
          <p>
            Hvis ikke leveringstidspunkt fremgår av bestillingsløsningen, skal
            selgeren levere varen til kjøper uten unødig opphold og senest samme
            dag etter bestillingen fra kunden. Varen skal leveres hos kjøperen
            med mindre annet er særskilt avtalt mellom partene.{" "}
          </p>
          <h3>7] Risikoen for varen</h3>
          <p>
            Risikoen for varen går over på kjøper når han, eller hans
            representant, har fått varene levert i tråd med punkt 6.{" "}
          </p>
          <h3>8] Angrerett </h3>
          <p>
            Med mindre avtalen er unntatt fra angrerett, kan kjøperen angre
            kjøpet av varen i henhold til angrerettloven.{" "}
          </p>
          <p>
            Kjøperen må gi selger melding om bruk av angreretten innen 14 dager
            fra fristen begynner å løpe. I fristen inkluderes alle
            kalenderdager. Dersom fristen ender på en lørdag, helligdag eller
            høytids- dag forlenges fristen til nærmeste virkedag.{" "}
          </p>
          <p>
            Angrefristen anses overholdt dersom melding er sendt før utløpet av
            fristen. Kjøper har bevisbyrden for at angreretten er blitt gjort
            gjeldende, og meldingen bør derfor skje skriftlig (angrerettskjema,
            e-post eller brev).{" "}
          </p>
          <p>Angrefristen begynner å løpe: </p>
          <ul>
            <li>
              <p>
                Ved kjøp av enkelt stående varer vil angrefristen løpe fra dagen
                etter varen(e) er mottatt.{" "}
              </p>
            </li>
            <li>
              Selges et abonnement, eller innebærer avtalen regelmessig levering
              av identiske varer, løper fristen fra dagen etter første
              forsendelse er mottatt.{" "}
            </li>
            <li>
              Ved kjøp av mat og drikke varer, kan ikke det gis noe angrerett.
              Dermed OM DATA AS kan ikke bestå noe form av angrerett når det
              gjelder kjøp av mat og drikke fra deres medlemsrestauranter. Det
              gjelder kun avbestilling av varer, som må gjøres raskt etter
              bestillingen av varer og må godkjennes av medlemsrestaurant som
              har mottatt den bestillingen
            </li>
            <li>
              <p>
                Består kjøpet av flere leveranser, vil angrefristen løpe fra
                dagen etter siste leveranse er mottatt.
              </p>
            </li>
          </ul>
          <p>
            Angrefristen utvides til 12 måneder etter utløpet av den
            opprinnelige fristen dersom selger ikke før avtaleinngåelsen
            opplyser om at det foreligger angrerett og standardisert
            angreskjema. Tilsvarende gjelder ved manglende opplysning om vilkår,
            tidsfrister og fremgangs måte for å benytte angre- retten. Sørger
            den næringsdrivende for å gi opp- lysningene i løpet av disse 12
            månedene, utløper angrefristen likevel 14 dager etter den dagen
            kjøperen mottok opplysningene.
          </p>{" "}
          <p>
            {" "}
            Kjøper kan prøve eller teste varen på en forsvarlig måte for å
            fastslå varens art, egenskaper og funksjon, uten at angreretten
            faller bort. Dersom prøving eller test av varen går utover hva som
            er forsvarlig og nødvendig, kan kjøperen bli ansvarlig for eventuell
            redusert verdi på varen.{" "}
          </p>
          <p>
            Selgeren er forpliktet til å tilbakebetale kjøpesum- men til
            kjøperen uten unødig opphold, og senest 14 dager fra selgeren fikk
            melding om kjøperens beslutning om å benytte angreretten. Selger har
            rett til å holde tilbake betalingen til han har mottatt varene fra
            kjøperen, eller til kjøper har lagt frem dokumentasjon for at varene
            er sendt tilbake.{" "}
          </p>
          <h3>
            9] Forsinkelse og manglende levering - kjøperens rettigheter og
            frist for å melde krav{" "}
          </h3>
          <p>
            Dersom selgeren ikke leverer varen eller leverer den for sent i
            henhold til avtalen mellom partene, og dette ikke skyldes kjøperen
            eller forhold på kjøperens side, kan kjøperen i henhold til reglene
            i forbrukerkjøpslovens kapittel 5 etter omstendighetene holde
            kjøpesummen tilbake, kreve oppfyllelse, heve avtalen og/eller kreve
            erstatning fra selgeren.{" "}
          </p>
          <p>
            Ved krav om misligholdsbeføyelser bør meldingen av bevishensyn være
            skriftlig (for eksempel e-post).{" "}
          </p>
          <ul>
            <li>
              <p style={{ fontWeight: "bold" }}>Oppfyllelse</p>
            </li>
            <p>
              Kjøper kan fastholde kjøpet og kreve oppfyllelse fra selger.
              Kjøper kan imidlertid ikke kreve oppfyllelse dersom det foreligger
              en hindring som selgeren ikke kan overvinne, eller dersom
              oppfyllelse vil medføre en så stor ulempe eller kostnad for selger
              at det står i vesentlig misforhold til kjøperens interesse i at
              selgeren oppfyller. Skulle vanskene falle bort innen rimelig tid,
              kan kjøper likevel kreve oppfyllelse.{" "}
            </p>
            <p>
              Kjøperen taper sin rett til å kreve oppfyllelse om han eller hun
              venter urimelig lenge med å fremme kravet.{" "}
            </p>
          </ul>
          <ul>
            <li>
              <p style={{ fontWeight: "bold" }}>Heving</p>
            </li>
            <p>
              Dersom selgeren ikke leverer varen på leveringstidspunktet, skal
              kjøperen oppfordre selger til å levere innen en rimelig
              tilleggsfrist for oppfyllelse. Dersom selger ikke leverer varen
              innen tilleggsfristen, kan kjøperen heve kjøpet.
            </p>
            <p>
              Kjøper kan imidlertid heve kjøpet umiddelbart hvis selger nekter å
              levere varen. Tilsvarende gjelder dersom levering til avtalt tid
              var avgjørende for inngåelsen av avtalen, eller dersom kjøperen
              har underrettet selger om at leveringstidspunktet er avgjørende.{" "}
            </p>
            <p>
              Leveres tingen etter tilleggsfristen forbrukeren har satt eller
              etter leveringstidspunktet som var avgjørende for inngåelsen av
              avtalen, må krav om heving gjøres gjeldende innen 24 timer etter
              at kjøperen fikk vite om leveringen.{" "}
            </p>
          </ul>
          <ul>
            <li>
              <p style={{ fontWeight: "bold" }}>Erstatning</p>
            </li>
            <p>
              Kjøperen kan kreve erstatning for lidt tap som følge av
              forsinkelsen. Dette gjelder imidlertid ikke dersom selgeren
              godtgjør at forsinkelsen skyldes hindring utenfor selgers kontroll
              som ikke med rimelighet kunne blitt tatt i betraktning på
              avtaletiden, unngått, eller overvunnet følgene av.
            </p>
          </ul>
          <h3>
            10] Mangel ved varen - kjøperens rettigheter og reklamasjonsfrist
          </h3>
          <p>
            Hvis det foreligger en mangel ved varen må kjøper innen rimelig tid
            (12 timer) etter at den ble oppdaget eller burde ha blitt oppdaget,
            gi selger melding om at han eller hun vil på berope seg mangelen.
            Kjøper har alltid reklamert tidsnok dersom det skjer innen 12 timer
            fra mangelen ble oppdaget eller burde blitt oppdaget. Reklamasjon
            kan skje senest 12 timer etter at kjøper overtok varen.{" "}
          </p>
          <p>
            Dersom varen har en mangel og dette ikke skyldes kjøperen eller
            forhold på kjøperens side, kan kjøperen i henhold til reglene i
            forbrukerkjøpsloven kapittel 6 etter omstendighetene holde kjøpe-
            summen tilbake, velge mellom retting og omlevering, kreve
            prisavslag, kreve avtalen hevet og/eller kreve erstatning fra
            selgeren.{" "}
          </p>
          <p>Reklamasjon til selgeren bør skje skriftlig. </p>
          <ul>
            <li>
              <p style={{ fontWeight: "bold" }}>Retting eller omlevering</p>
            </li>
            <p>
              Kjøperen kan velge mellom å kreve mangelen rettet eller levering
              av tilsvarende ting. Selger kan likevel motsette seg kjøperens
              krav dersom gjennomføringen av kravet er umulig eller volder
              selgeren urimelige kostnader. Retting eller omlevering skal
              foretas innen rimelig tid. Selger har i utgangs- punktet ikke rett
              til å foreta mer enn to avhjelps- forsøk for samme mangel.
            </p>
          </ul>
          <ul>
            <li>
              <p style={{ fontWeight: "bold" }}>Prisavslag</p>
            </li>
            <p>
              Kjøper kan kreve et passende prisavslag dersom varen ikke blir
              rettet eller omlevert. Dette inne- bærer at forholdet mellom
              nedsatt og avtalt pris svarer til forholdet mellom tingens verdi i
              mangelfull og kontraktsmessig stand. Dersom særlige grunner taler
              for det, kan prisavslaget i stedet settes lik mangelens betydning
              for kjøperen.
            </p>
          </ul>
          <ul>
            <li>
              <p style={{ fontWeight: "bold" }}>Heving</p>
            </li>
            <p>
              Dersom varen ikke er rettet eller omlevert, kan kjøperen også heve
              kjøpet når mangelen ikke er uvesentlig
            </p>
          </ul>
          <h3>11] Selgerens rettigheter ved kjøperens mislighold </h3>
          <p>
            Dersom kjøperen ikke betaler eller oppfyller de øvrige pliktene
            etter avtalen eller loven, og dette ikke skyldes selgeren eller
            forhold på̊ selgerens side, kan selgeren i henhold til reglene i
            forbruker- kjøpsloven kapittel 9 etter omstendighetene holde varen
            tilbake, kreve oppfyllelse av avtalen, kreve avtalen hevet samt
            kreve erstatning fra kjøperen. Selgeren vil også̊ etter
            omstendighetene kunne kreve renter ved forsinket betaling,
            inkassogebyr og et rimelig gebyr ved uavhentede varer.{" "}
          </p>
          <ul>
            <li>
              <p style={{ fontWeight: "bold" }}>Oppfyllelse</p>
            </li>
            <p>
              Selger kan fastholde kjøpet og kreve at kjøperen betaler
              kjøpesummen. Er varen ikke levert, taper selgeren sin rett dersom
              han venter urimelig lenge med å fremme kravet.
            </p>
          </ul>
          <ul>
            <li>
              <p style={{ fontWeight: "bold" }}>Heving</p>
            </li>
            <p>
              Selger kan heve avtalen dersom det foreligger vesentlig
              betalingsmislighold eller annet vesentlig mislighold fra kjøperens
              side. Selger kan likevel ikke heve dersom hele kjøpesummen er
              betalt. Fastsetter selger en rimelig tilleggsfrist for oppfyllelse
              og kjøperen ikke betaler innen denne fristen, kan selger heve
              kjøpet.
            </p>
          </ul>
          <ul>
            <li>
              <p>
                Renter ved forsinket betaling/inkassogebyr Dersom kjøperen ikke
                betaler kjøpesummen i hen- hold til avtalen, kan selger kreve
                renter av kjøpe- summen etter forsinkelsesrenteloven. Ved
                manglende betaling kan kravet, etter forutgående varsel, bli
                sendt til inkasso. Kjøper kan da bli holdt ansvarlig for gebyr
                etter inkassoloven.{" "}
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>Gebyr ved uavhentede ikke-forskuddsbetalte varer </p>
            </li>
          </ul>
          <p>
            Dersom kjøperen unnlater å hente ubetalte varer, kan selger belaste
            kjøper med et gebyr. Gebyret skal maksimalt dekke selgerens faktiske
            utlegg for å levere varen til kjøperen. Et slikt gebyr kan ikke
            belastes kjøpere under 18 år.{" "}
          </p>
          <h3>12] Garanti</h3>
          <p>
            Garanti som gis av selgeren eller produsenten, gir kjøperen
            rettigheter i tillegg til de kjøperen allerede har etter ufravikelig
            lovgivning. En garanti inne- bærer dermed ingen begrensninger i
            kjøperens rett til reklamasjon og krav ved forsinkelse eller mangler
            etter punkt 9 og 10.{" "}
          </p>
          <h3>13] Personopplysninger </h3>
          <p>
            Behandlingsansvarlig for innsamlede personopplysninger er selger og
            deres medlemsrestaurantbedrifter. Medmindre kjøperen samtykker til
            noe annet, kan selgeren, i tråd med personopplysningsloven, kun
            innhente og lagre de person- opplysninger som er nødvendig for at
            selgeren og deres medlemsrestauranter skal kunne gjennomføre
            forpliktelsene etter avtalen. Kjøperens personopplysninger vil kun
            bli utlevert til andre hvis det er nødvendig for at selger skal få
            gjennomført avtalen med kjøperen, eller i lovbestemte tilfelle.{" "}
          </p>
          <h3>14] Konfliktløsning</h3>
          <p>
            Klager rettes til selger innen rimelig tid, jf. punkt 9 og 10.
            Partene skal forsøke å løse eventuelle tvister i minnelighet.{" "}
          </p>
          <p>E-post : info@omdata.world </p>
          <p>OM DATA Tel: +47 916 38 483</p>
          <p>
            Dersom dette ikke lykkes, kan kjøperen ta kontakt med Forbrukerrådet
            for mekling. Forbrukerrådet er tilgjengelig på telefon 23 400 500
            eller www.forbrukerradet.no.{" "}
          </p>
          <p style={{ fontSize: 11 }}>
            SALGSBETINGELSER FOR FORBRUKERKJØP AV VARER OVER INTERNETT{" "}
          </p>
        </Container>
      </div>
    );
  }
}
