export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const DELETE_CART = "DELETE_CART";
export const GET_ITEM_GRAND_TOTAL = "GET_ITEM_GRAND_TOTAL";
export const SET_GRAND_QUANTITY = "SET_GRAND_QUANTITY";
export const REMOVE_EXTRA_ITEM_FROM_CART = "REMOVE_EXTRA_ITEM_FROM_CART";

export const addItemToCart = (payload) => ({
  type: ADD_ITEM_TO_CART,
  payload
});

export const increase_Quantity = (payload) => ({
  type: INCREASE_QUANTITY,
  payload
});

export const decrease_Quantity = (payload) => ({
  type: DECREASE_QUANTITY,
  payload
});

export const removeItemFromCart = (payload) => ({
  type: REMOVE_ITEM_FROM_CART,
  payload
});

export const removeExtraItemFromCart = (payload) => ({
  type: REMOVE_EXTRA_ITEM_FROM_CART,
  payload
});

export const deleteCart = () => ({
  type: DELETE_CART
});
