import axios from "axios";
import React ,{useEffect} from "react";
import { useParams } from "react-router-dom";
import globalConst from "../helpers/global";

const VippsMachineFallbackPage = () => {
  const { id } = useParams();

  const checkPaymentConfirmationVippsMachine = async (data) => {
    try {
      const response = await axios.post(
        `${globalConst.serverip}public/tableOrderConfirmationVippsMachine`,
        data,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      console.log("res is", response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
      console.log("came at  fallback")
      console.log("id",id);
    const data = {
        orderId: id
    };

    checkPaymentConfirmationVippsMachine(data);
  }, []);

  return <div>VippsMachineFallbackPage</div>;
};

export default VippsMachineFallbackPage;
