import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Button } from "reactstrap";
import { clearPaymentData, clearStorage, paymentConfirmActionTable } from "../actions";
import remove from "../assets/remove.png";

const TableOrderConfirmVipps = () => {

  const slug = useSelector((state) => state.restaurant.slug);
  const state = useSelector((state) => state.paymentConfirmReducer);
  const history = useHistory();

  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    
    const data = {
      id: id,
    };
    
    dispatch(paymentConfirmActionTable(data)).then(() =>{
      setloading(false)
    }).catch(() =>{
      setloading(false)
    });
     localStorage.removeItem("Vipps");
    
  }, []);

  

  const clearState = () => {
    dispatch(clearStorage());
    history.push(`/restaurant/booktable/${slug}`);
  };

  const tryPaymentAgain =()=>{
    dispatch(clearPaymentData())
    history.push('/tableorder/cart')

  }

    return (
        <Card className="checkout-card">
      <CardHeader>Order Details</CardHeader>
      {loading ? (
        <h3 align="center">Loading....</h3>
      ) : (
        <CardBody style={{textAlign:"center"}}>
          {state.data.status == 400 ? (
            <img src={remove} height={140} />
          ) : (
            <>
              <div className="checkmark-circle">
                <div className="background" />
                <div className="checkmark draw" />
              </div>
            </>
          )}
          {state.data.status == 400 ? (
            <>
              {" "}
              <div>
                <p>
                  Noen problemer oppstod.
                  <br />
                  Betaling ikke mottatt fra slutten
                </p>
                <Button color="success" outline onClick={() => clearState()}>
                G� til hjemmesiden
                </Button>
                <Button  color ='success' outline onClick={() => {tryPaymentAgain()}}>Pr�v betaling p� nytt</Button>
              </div>
            </>
          ) : (
            <>
              <div>
                <h1 className="display-4">
                  <strong>Order Confirmed</strong>
                </h1>
                <p>
                Your food will reach on your table soon
                </p>
                <Button color="success" outline onClick={() => clearState()}>
                  {/* Place New Order */}
                  Legg inn ny ordre
                </Button>
              </div>
            </>
          )}
        </CardBody>
      )}
    </Card>
    )
}

export default TableOrderConfirmVipps
