import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import globalConst from "../helpers/global";
import queryString from "query-string";
import loadingGif from "../assets/loading-gif.gif";
import wentWrong from "../assets/error-gif.gif";
import paySuccess from "../assets/success.gif";
const ReserveTableFallbackDintreero = (props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [successMsg, setSuccessMsg] = useState({});

  useEffect(async () => {
    try {
      var query = queryString.parse(props.location.search);
      console.log("id", query.merchant_reference);
      console.log("query.transaction_id", query.transaction_id);
      const response = await axios.post(
        `${
          globalConst.serverip
        }api/v1/public/confirmResreveTablePaymentdinterro`,
        { id: query.merchant_reference, transactionId: query.transaction_id },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response.data.status == 200) {
        console.log("response", response);

        let msg = {};
        
        if(response.data.data.price > 0){
          msg.greeting = 'Payment Successfull';
          msg.message = "Thank you for your payment, You'll get the confirmation SMS or E-Mail from the Restaurant soon.";
        } else {
          msg.greeting = '';
          msg.message = "Thank you, You'll get the confirmation SMS or E-Mail from the Restaurant soon.";
        }

        setSuccessMsg(msg);
        setLoading(false);
        console.log("response after payment confirm in dinterro", response);
      } else {
        setLoading(false);
        setIsError(true);
      }
    } catch (error) {
      setLoading(false);
      setIsError(true);
    }
  }, []);

  return loading ? (
    <div className="loading-gif">
      {/* <h3>Loading....</h3> */}
      <img src={loadingGif} alt="Loading" width="80px" />
    </div>
  ) : isError ? (
    <div className="error-page d-flex flex-column justify-content-center align-items-center">
      <img src={wentWrong} alt="Loading" width="150px" />
      <h3>Something went wrong</h3>

      <a className="btn btn-lg btn-success mt-5" href="https://treedrive.no">Go Back</a>
    </div>
  ) : (
    <div className="success-payment d-flex flex-column justify-content-center align-items-center">
      <img src={paySuccess} alt="Loading" width="250px" />
      <h3>{successMsg.greeting}</h3>
      <p className="text-center">
        {successMsg.message}
      </p>

      <a className="btn btn-lg btn-success mt-5" href="https://treedrive.no">Go Back</a>

    </div>
  );
};

export default ReserveTableFallbackDintreero;
