import { PAYMENT_CONFIRM_FAIL, PAYMENT_CONFIRM_START, PAYMENT_CONFIRM_SUCCESS } from "../actions"

const initialState ={
    loading:false,
    data:{}
}

const paymentConfirmReducer =(state=initialState,action)=>{
    switch (action.type) {
        case PAYMENT_CONFIRM_START:
            return{
                ...state,
                loading:true
            }
        case PAYMENT_CONFIRM_SUCCESS:
            return {
            ...state,
            loading:false,
            data:action.payload
            }  
        case     
            PAYMENT_CONFIRM_FAIL:
            return{
                ...state,
                loading:false,
                data:action.payload
            }
            case     
            'CLEAR':
            return{
                ...state,
                loading:false,
                data:{}
            }   
         default:return state 
            
    }
    
}
export default paymentConfirmReducer; 