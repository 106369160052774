import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col
} from "reactstrap";
import { Tag } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getTaxModule } from "../helpers/taxmodule";
import React, { Fragment, useEffect, useState } from "react";
import Container from "reactstrap/lib/Container";
import products from "../reducers/productsReducer";
import globalConst from "../helpers/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faHamburger } from "@fortawesome/free-solid-svg-icons";
import {
  placeTableOnlineOrder,
  tableOrder_payment_hitting,
  AddTableToCart,
  payment_and_order_staus,
  RemoveTableFromCart,
  removeTableCartItem
} from "../actions";

import { SpinnerRoundFilled } from "spinners-react";
import ModelComp from "./ModelComp";
import {
  decrease_Quantity,
  increase_Quantity,
  removeExtraItemFromCart,
  removeItemFromCart,
  setGrandTotal
} from "../actions/cartActions";
let isPaymentSuccess = true;
let isOrdered = false;
const TableCheckout = (props) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [extraItemsPrice, setExtraItemPrice] = useState(0);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  let [tax, setTax] = useState(0);
  let [taxValue, setTaxValue] = useState(0);
  const isVippsPermission = useSelector(
    (state) => state.restaurant.additional.vippsPermission
  );

  const webpayment= useSelector(
    (state) => state.restaurant.webpayment
  );
  const cartData = useSelector((state) => {
    return state.cartReducer;
  });
  console.log("cart data", cartData);

  const onAddQuantity = (item) => {
    dispatch(increase_Quantity(item._uniqueId));
  };
  const onRemoveQuantity = (item) => {
    if (item.quantity > 1) {
      dispatch(decrease_Quantity(item._uniqueId));
    }
  };

  const extraItemRemoved = (uId, id) => {
    console.log("uid", uId);
    console.log("id", id);
    dispatch(removeExtraItemFromCart({ uniqueId: uId, id }));
  };

  const onPlaceOrder = (isVipps) => {
    setIsLoading(true);
    const tableData = JSON.parse(localStorage.getItem("tableData"));
    if (!tableData) {
      props.history.replace("/restaurant/booktable/" + restaurant.slug);
    }
    localStorage.removeItem("Vipps");
    if (isVipps) {
      localStorage.setItem("Vipps", true);
    }
    dispatch(
      placeTableOnlineOrder(
        restaurant.id,
        order.userId,
        cartData.items,
        cartData.grandTotal,
        "table",
        tax,
        tableData
      )
    );
  };

  const restaurant = useSelector((state) => {
    return state.restaurant;
  });

  const products = useSelector((state) => {
    return state.products;
  });

  const order = useSelector((state) => {
    return state.order;
  });

  const onRemoveItem = (item) => {
    dispatch(removeItemFromCart(item._uniqueId));
  };
  useEffect(() => {
    console.log("--------webpayment",webpayment)
    const tableNumber = JSON.parse(localStorage.getItem("tableData"));
    const myTax = getTaxModule(restaurant.taxes, "table");
    console.log("myTax", myTax);

    setTax(myTax);
    if (!tableNumber) {
      props.history.replace("/restaurant/booktable/" + restaurant.slug);
    }
  }, []);

  useEffect(() => {
      setTaxValue((100 + Number(tax)) / 100);
  }, [tax]);

  useEffect(() => {
      if (order.orderSuccess) {
        // useDispatch()
        isOrdered = false;
        var orderId = order.orderData;
        var orderType = order.orderType;
        var crtAr = [];
        cartData.items.forEach((element) => {
          var crt = {
            id: element._id,
            line_id: element._uniqueId.toString(),
            description: element.name,
            quantity: element.quantity,
            amount: Number(element.price) * 100,
            vat_amount: Number(element.price),
            vat: tax
          };
          crtAr.push(crt);
        });

      dispatch(tableOrder_payment_hitting(orderId, cartData.grandTotal, crtAr));
      }
  }, [order.orderSuccess]);

  useEffect(() => {
      if (order.paymentHittingSuccess) {
        isPaymentSuccess = false;
        if (order.paymentHittingData.url) {
          dispatch(payment_and_order_staus());
          window.open(order.paymentHittingData.url, "_self");
        }
      }
  }, [order.paymentHittingSuccess]);

  return (
    // <Fragment>

    //    <ModelComp isTrue={isLoading}/>

    //   {cartData.cart && cartData.cart.length > 0 && tax && taxValue ? (
    //     <Container fluid={true}  className="CartItems">
    //       {cartData.cart.map((sinItem) => {
    //         return (
    //           <Container fluid={true} style={{ padding: 0, margin: 0 }}>
    //             <Row style={{ padding: "10px 5px"}}>
    //               <Col xl="6" lg="6" md="8" sm="12" style={{ margin: "auto" }}>
    //                 <Card
    //                   style={{
    //                     height: "9rem",
    //                     borderRadius: "10px",
    //                     position: "realtive"
    //                   }}
    //                 >
    //                   <Button
    //                     close
    //                     style={{ position: "absolute", right: "6px", top: 0 }}
    //                     onClick={() => onRemoveItem(sinItem)}
    //                   />

    //                   <CardBody>
    //                     <div
    //                       style={{
    //                         display: "flex",
    //                         justifyContent: "space-between"
    //                       }}
    //                     >
    //                       {sinItem.thumbnail ? (
    //                         <img
    //                           src={`${globalConst.s3url}${sinItem.thumbnail}`}
    //                           width="100px"
    //                           height="100px"
    //                         />
    //                       ) : (
    //                         <img
    //                           width="100"
    //                           px
    //                           height="100px"
    //                           src={`${globalConst.s3url}${sinItem.image}`}
    //                         />
    //                       )}

    //                       <div
    //                         style={{
    //                           display: "flex",
    //                           flexDirection: "column",
    //                           justifyContent: "space-evenly"
    //                         }}
    //                       >
    //                         <p>{sinItem.name}</p>
    //                         <p>Kr.{sinItem.totalPrice}</p>
    //                       </div>
    //                       <div
    //                         style={{
    //                           display: "flex",
    //                           height: "6rem",
    //                           alignItems: "center",
    //                           justifyContent: "space-evenly"
    //                         }}
    //                       >
    //                         <div style={{}}>
    //                           <span
    //                             style={{ cursor: "pointer" }}
    //                             onClick={() => onAddQuantity(sinItem)}
    //                           >
    //                             +
    //                           </span>
    //                           <span
    //                             style={{
    //                               margin: "0 9px",
    //                               border: "1 solid black"
    //                             }}
    //                           >
    //                             {sinItem.quantity}
    //                           </span>
    //                           <span
    //                             style={{ cursor: "pointer" }}
    //                             onClick={() => onRemoveQuantity(sinItem)}
    //                           >
    //                             -
    //                           </span>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </CardBody>
    //                 </Card>
    //               </Col>
    //             </Row>
    //           </Container>
    //         );
    //       })}
    //       <Row style={{ padding: "0 5px" }}>
    //         <Col xl="4" md="4" sm="12" style={{ margin: "auto" }}>
    //           <Card style={{  borderRadius: "10px" }} className="outerCard">
    //             <CardBody style={{ background: "rgba(0,0,0,)" }} className="CardItem">
    //               <div
    //                 style={{
    //                   display: "flex",
    //                   justifyContent: "space-evenly"
    //                 }}
    //               >
    //                 <p>Subtotal</p>
    //                 <p> Kr. {(cartData.totalPrice / taxValue).toFixed(2)}</p>
    //               </div>
    //               <div
    //                 style={{
    //                   display: "flex",
    //                   justifyContent: "space-evenly"
    //                 }}
    //               >
    //                 <p>tax({tax}%)</p>
    //                 <p>
    //                   {" "}
    //                   Kr.{" "}
    //                   {(
    //                     cartData.totalPrice -
    //                     cartData.totalPrice / taxValue
    //                   ).toFixed(2)}
    //                 </p>
    //               </div>
    //               <hr className="lineContainer"/>
    //               <div
    //                 style={{
    //                   display: "flex",
    //                   justifyContent: "space-evenly",

    //                 }}
    //               >
    //                 <p>Total {` (${cartData.totalQuantity} items)`} </p>
    //                 <p> Kr. {cartData.totalPrice}</p>
    //               </div>
    //             </CardBody>
    //           </Card>
    //         </Col>
    //       </Row>
    //       <Container className="ButtonContainer">
    //       <Row style={{ postion: "fixed", bottom: 0, left: 0 }}>
    //         <Col xl={isVippsPermission?"4":"8"} md={isVippsPermission?"4":"8"} sm="12" >
    //           <Button
    //             color="success"
    //             size="lg"
    //             xl="4"
    //             block
    //             onClick={()=>onPlaceOrder(false)}
    //             className="tableOrderButton"

    //           >
    //             Pay With Card Kr. {cartData.totalPrice}
    //           </Button>
    //           </Col>
    //           {isVippsPermission &&  <Col xl="4" md="4" sm="12"  className="ButtonPay">
    //           <Button
    //             color="warning"
    //             size="lg"
    //             xl="4"
    //             block
    //             onClick={()=>onPlaceOrder(true)}
    //             className="tableOrderButtonVipps vippssCard"
    //           >
    //             {/* Pay With Vipps Kr. {cartData.totalPrice} */}
    //           </Button>

    //         </Col>}

    //       </Row>
    //       </Container>
    //     </Container>
    //   ) : (
    //     <div
    //       style={{
    //         width: "100%",
    //         height: "100vh",
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "center",
    //         alignItems: "center"
    //       }}
    //     >
    //       <FontAwesomeIcon icon={faCartPlus} color="grey" size="2x" />
    //       <h5 style={{ margin: "20px 0" }}>Your Cart is Empty</h5>
    //       <Button
    //         color="danger"
    //         onClick={() => {
    //           props.history.replace("/restaurant/booktable/" + restaurant.slug);
    //         }}
    //       >
    //         {" "}
    //         Go to menu
    //       </Button>
    //     </div>
    //   )}
    // </Fragment>
    <Fragment>
      <ModelComp isTrue={isLoading} />

      {cartData && cartData.items.length > 0 && tax && taxValue ? (
        <Container fluid={true} className="CartItems">
          <div className="checkoutMain bg">
            {cartData.items.map((item) => {
              return (
                <Container fluid={true} style={{ padding: 0, margin: 0 }}>
                  <Row className="mainRowWrapper">
                    <Col
                      xl="12"
                      lg="12"
                      md="8"
                      sm="12"
                      style={{ margin: "auto" }}
                    >
                      <Card
                        style={{
                          // height: "9rem",
                          borderRadius: "10px",
                          position: "realtive"
                          // marginTop:"30px"
                        }}
                      >
                        <Button
                          close
                          style={{ position: "absolute", right: "6px", top: 0 }}
                          onClick={() => onRemoveItem(item)}
                        />
                        <CardBody>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            {/* {item.thumbnail ? (
                              <img
                                src={`${globalConst.s3url}${item.thumbnail}`}
                                width="70px"
                                height="70px"
                              />
                            ) : (
                              <img
                                width="70"
                                px
                                height="70px"
                                src={`${globalConst.s3url}${item.image}`}
                              />
                            )} */}

                            <p className="productTitle">{item.name}</p>
                            {/* <p className="productTitle">Kr.{item.totalPrice}</p> */}

                            {/* <div>
                            // {item.ingredientsNotRequired.length > 0 && (
                            //   <div>
                            //     ingredents:
                            //     {item.ingredientsNotRequired.map((item) => {
                            //       return (
                            //         <>
                            //           <p>{` ${item} not required`}</p>
                            //         </>
                            //       );
                            //     })}
                            //   </div>
                            // )}

                            {item.instructions != "" && (
                              <span>
                                {" "}
                                instructions:
                                {`${item.instructions}`}
                              </span>
                            )}
                            {item.spiciness && (
                              <div>Spiciness:{item.spiciness}</div>
                            )}
                            {item.extraItems.length > 0 && (
                              <div>
                                EXTRAS:
                                {item.extraItems.map((item1) => {
                                  return (
                                    <>
                                      <p>{` ${item1.name} kr.${
                                        item1.price
                                      }`}</p>
                                      <button
                                        onClick={() =>
                                          extraItemRemoved(
                                            item._uniqueId,
                                            item1.id
                                          )
                                        }
                                      >
                                        -
                                      </button>
                                    </>
                                  );
                                })}
                              </div>
                            )}
                          </div> */}

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly"
                              }}
                            />
                            {/* <div className="addItems"
                              style={{
                                display: "flex",
                                height: "2rem",
                                // alignItems: "center",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <div style={{}}>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onAddQuantity(item)}
                                >
                                  +
                                </span>
                                <span
                                  style={{
                                    margin: "0 9px",
                                    border: "1 solid black",
                                    cursor: "pointer",
                                  }}
                                >
                                  {item.quantity}
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onRemoveQuantity(item)}
                                >
                                  -
                                </span>
                              </div>
                            </div> */}
                            <p className="productTitle">Kr.{item.totalPrice}</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px"
                            }}
                          >
                            {item.ingredientsNotRequired.length > 0 && (
                              <div>
                                <p
                                  className="productTitle"
                                  style={{ lineHeight: "0.5em" }}
                                >
                                  ingredents:
                                </p>
                                {item.ingredientsNotRequired.map((item) => {
                                  return (
                                    <>
                                      <p className="ingredientTitle">{`Uten ${item}`}</p>
                                    </>
                                  );
                                })}
                              </div>
                            )}
                            <div>
                              {item.spiciness && (
                                <div className="productTitle">
                                  Spiciness:
                                  <br />
                                  {item.spiciness}
                                </div>
                              )}
                              {/* {item.instructions != "" && (
                                <span>
                                  {" "}
                                  <p className="productTitle">instructions:</p>
                                  {`${item.instructions}`}
                                </span>
                              )} */}
                              {/* {item.spiciness && (
                                <div className="productTitle">
                                  Spiciness:{item.spiciness}
                                </div>
                              )} */}
                            </div>
                          </div>
                          {item.instructions != "" && (
                            <span>
                              {" "}
                              <p
                                className="productTitle"
                                style={{ lineHeight: "0.5em" }}
                              >
                                instructions:
                              </p>
                              {`${item.instructions}`}
                            </span>
                          )}

                          {item.extraItems.length > 0 && (
                            <div>
                              <p className="productTitle">EXTRAS:</p>
                              {item.extraItems.map((item1) => {
                                return (
                                  <div className="extraWrapper">
                                    <Tag className="tagContainer">
                                      <p>{` ${item1.name} kr.${item1.price}`}</p>
                                      <button
                                        className="removebutton"
                                        onClick={() =>
                                          extraItemRemoved(
                                            item._uniqueId,
                                            item1._id
                                          )
                                        }
                                      >
                                        -
                                      </button>
                                    </Tag>
                                    {/* <button className="removebutton"
                                        onClick={() =>
                                          extraItemRemoved(
                                            item._uniqueId,
                                            item1.id
                                          )
                                        }
                                      >
                                      -
                                      </button> */}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              );
            })}

            <Row style={{ padding: "0 5px" }}>
              <Col xl="4" md="4" sm="12" className="subTotalwrapper">
                <Card
                  style={{ borderRadius: "10px", width: "400px" }}
                  className="outerCard "
                >
                  <CardBody
                    style={{ background: "rgba(0,0,0,)" }}
                    className="CardItem"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <p>Subtotal</p>
                      <p>
                        {" "}
                        Kr.{" "}
                        {(
                          cartData.grandTotal && cartData.grandTotal / taxValue
                        ).toFixed(2)}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <p>tax({tax}%)</p>
                      <p>
                        {" "}
                        Kr.{" "}
                        {(
                          cartData.grandTotal -
                          cartData.grandTotal / taxValue
                        ).toFixed(2)}
                      </p>
                    </div>
                    <hr className="lineContainer" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <p>Total {` (${cartData.items.length} items)`} </p>
                      <p> Kr. {cartData.grandTotal}</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Container className="ButtonContainer">
              <Row style={{ postion: "fixed", bottom: 0, left: 0 }}>
              {webpayment!=="none"?(
                <Col
                  xl={isVippsPermission ? "4" : "8"}
                  md={isVippsPermission ? "4" : "8"}
                  sm="12"
                >
                  <Button
                    color="success"
                    size="lg"
                    xl="4"
                    block
                    onClick={() => onPlaceOrder(false)}
                    className="tableOrderButton"
                  >
                    Pay With Card Kr. {cartData.grandTotal}
                  </Button>:<></>
                </Col>
                ):("")}
                {isVippsPermission && (
                  <Col xl="4" md="4" sm="12" className="ButtonPay">
                    <Button
                      color="warning"
                      size="lg"
                      xl="4"
                      block
                      onClick={() => onPlaceOrder(true)}
                      className="tableOrderButtonVipps vippssCard"
                    >
                      {/* Pay With Vipps Kr. {cartData.totalPrice} */}
                    </Button>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        </Container>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FontAwesomeIcon icon={faCartPlus} color="grey" size="2x" />
          <h5 style={{ margin: "20px 0" }}>Your Cart is Empty</h5>
          <Button
            color="danger"
            onClick={() => {
              props.history.replace(
                "/restaurant/tableorder/" + restaurant.slug
              );
            }}
          >
            {" "}
            Go to menu
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default TableCheckout;
