import React, { Component } from "react";
// import logo from './logo.svg';
import "./App.css";
import store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Menu from "./components/Menu";
import Checkout from "./components/Checkout";
import Paymentcheckout from "./components/Paymentcheckout";
import PublicTablicMenu from "./components/plu1tableMenu";
import TableNumber from "./components/TableNumber";
import TableCheckout from "./components/TableCheckout";

import MerchantTerm from "./components/MerchantTerm";
import RestaurantTerm from "./components/RestaurantTerms";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIgloo } from "@fortawesome/free-solid-svg-icons";
import VippsMachineFallbackPage from "./components/VippsMachineFallbackPage";
import ReserveTable from "./components/ReserveTable";
import reserveTableFallbackPage from "./components/reserveTableFallbackPage";
import ReserveTableFallbackDintreero from "./components/ReserveTableFallbackDintreero";

import ReserveTableFallbackTidy from "./components/ReserveTableFallbackTidy";

library.add(faIgloo);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <Router>
            <Switch>
              <Route
                path="/checkPaymentConfirmationVippsMachine/vipps/:id"
                component={VippsMachineFallbackPage}
              />
              <Route path="/restaurant/checkout" component={Checkout} />
              <Route exact path="/restaurant/:id" component={Menu} />
              <Route path="/checkout/:id" component={Checkout} />
              <Route path="/paymentcheckout" component={Paymentcheckout} />
              <Route path="/merchantterm/" component={MerchantTerm} />
              <Route path="/restaurantterm/:slug" component={RestaurantTerm} />

              <Route
                exact
                path="/restaurant/tableorder/:slug"
                component={PublicTablicMenu}
              />
              <Route
                exact
                path="/restaurant/booktable/:slug"
                component={TableNumber}
              />

              <Route
                exact
                path="/restaurant/booktable/:slug/:appCustomerId"
                component={TableNumber}
              />
              <Route exact path="/tableorder/cart" component={TableCheckout} />
              <Route
                exact
                path="/restaurant/reservetable/:slug"
                component={ReserveTable}
              />
              <Route
                path="/reserveTableFallbackPage/vipps/:id"
                component={reserveTableFallbackPage}
              />
               <Route
                path="/reservetablefallbackdintreero"
                component={ReserveTableFallbackDintreero}
              />

              <Route
                path="/reservetablefallbacktidy"
                component={ReserveTableFallbackTidy}
              />

            </Switch>
          </Router>
        </div>
      </Provider>
    );
  }
}

export default App;
