import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Button } from "reactstrap";
import {
  clearPaymentData,
  clearStorage,
  paymentConfirmAction
} from "../actions";
import remove from "../assets/remove.png";
import axios from "axios";
import globalConst from "../helpers/global";
const VippsOtp = ({ props }) => {
  const slug = useSelector((state) => state.restaurant.slug);
  const state = useSelector((state) => state.paymentConfirmReducer);

  const history = useHistory();

  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    
    const data = {
      id: id
    };
    
    dispatch(paymentConfirmAction(data))
      .then(() => {
       
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });

    localStorage.removeItem("Vipps");
  }, []);

  // useEffect(
  //   () => {
  //      setloading(false);
  //   },
  //   [state.data.otp]
  // );

  const clearState = () => {
    dispatch(clearStorage());
    history.push(`/restaurant/${slug}`);
  };
  const cancelVippsPayment = async (id) => {
    const response = await axios.post(
      `${globalConst.serverip}public/cancelvippspayment`,
      { orderId: id },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    
  };
  const refundPayment = async (id) => {
    const response = await axios.post(
      `${globalConst.serverip}public/refundvippspayment`,
      { orderId: id },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  
  };
  const paymentDetailsVipps = async (id) => {
    const response = await axios.post(
      `${globalConst.serverip}public/vippspaymentdetails`,
      { orderId: id },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    
  };
  const paymentAgainClicked = () => {
    dispatch(clearPaymentData());
    history.go(-1);
  };

  return (
    <>
      <Card className="checkout-card">
        <CardHeader>Order Details</CardHeader>
        {loading ? (
          <h3 align="center">Loading....</h3>
        ) : (
          <CardBody style={{ textAlign: "center" }}>
            {state.data.status == 400 ? (
              <img src={remove} height={140} />
            ) : (
              <>
                <div className="checkmark-circle">
                  <div className="background" />
                  <div className="checkmark draw" />
                </div>
              </>
            )}
            {state.data.status == 400 ? (
              <>
                {" "}
                <div>
                  <p>
                    Noen problemer oppstod.
                    <br />
                    Betaling ikke mottatt fra slutten
                  </p>
                  <Button color="success" outline onClick={() => clearState()}>
                  Gå til hjemmesiden
                  </Button>
                  <Button
                    color="success"
                    outline
                    onClick={() => paymentAgainClicked()}
                  >
                    Prøv betaling på nytt
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <h1 className="display-4">
                    <strong>OTP: {state.data.otp ? state.data.otp : ""}</strong>
                  </h1>
                  <p  style={{fontWeight: "bold"}}>
                  Takk for bestillingen. Bestillingen din er fullført. For ordrebekreftelse, vennligst sjekk din e-post eller melding sendt til ditt telefonnummer.. 
                  </p>
                  <p  style={{fontWeight: "bold"}}>
                    {/* You need to provide OTP at the restaurant to collect your order. */}
                    Du må oppgi OTP på restauranten for å hente bestillingen
                    <br />
                    {/* We have also send the OTP to your email address. */}
                    Vi har også sendt OTP til e-postadressen din
                  </p>
                  <Button color="success" outline onClick={() => clearState()}>
                    {/* Place New Order */}
                    Legg inn ny ordre
                  </Button>
                </div>
              </>
            )}
          </CardBody>
        )}
      </Card>
      {/* <Button onClick={()=>{cancelVippsPayment(id)}}>Cancel Order</Button>
    <Button onClick={()=>refundPayment(id)}>Refund</Button>
    <Button onClick={()=>paymentDetailsVipps(id)}>Payment Details</Button> */}
    </>
  );
};

export default VippsOtp;
