import globalConst from "../helpers/global";
import axios from 'axios';
export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const ONLY_TOGGLE_MODAL = "ONLY_TOGGLE_MODAL";
export const ADD_QUANTITY = "ADD_QUANTITY";
export const REMOVE_QUANTITY = "REMOVE_QUANTITY";
export const ADD_TO_CART = "ADD_TO_CART";
export const GET_CART = "GET_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_EXTRA_FROM_CART = "REMOVE_EXTRA_FROM_CART";
export const CHANGE_QUANTITY = "CHANGE_QUANTITY";
export const TOGGLE_DELIVERY_MODAL = "TOGGLE_DELIVERY_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const ONLINE_TABLEORDER_SUCCESS = "ONLINE_TABLEORDER_SUCCESS";
//restaurant
export const GET_RESTAURANT_BEGIN = "GET_RESTAURANT_BEGIN";
export const GET_RESTAURANT_SUCCESS = "GET_RESTAURANT_SUCCESS";
export const GET_RESTAURANT_FAILURE = "GET_RESTAURANT_FAILURE";
export const PAYMENT_AND_ORDER_STATUS = "PAYMENT_AND_ORDER_STATUS"; ///order
export const CHECK_USER = "CHECK_USER";
export const CHECK_USER_BEGIN = "CHECK_USER_BEGIN";
export const CHECK_USER_SUCCESS = "CHECK_USER_SUCCESS";
export const CHECK_USER_FAILURE = "CHECK_USER_FAILURE";
export const USER_FORM_CHANGE = "USER_FORM_CHANGE";

export const USER_DELIVER_FORM_CHANGE = "USER_DELIVER_FORM_CHANGE";
export const EDIT_USER = "EDIT_USER";
export const RESET_ORDER = "RESET_ORDER";

export const ONLINE_ORDER_BEGIN = "ONLINE_ORDER_BEGIN";
export const ONLINE_ORDER_SUCCESS = "ONLINE_ORDER_SUCCESS";
export const ONLINE_ORDER_FAILURE = "ONLINE_ORDER_FAILURE";

export const GET_SUPERCATEGORY_BEGIN = "GET_SUPERCATEGORY_BEGIN";
export const GET_SUPERCATEGORY_SUCCESS = "GET_SUPERCATEGORY_SUCCESS";
export const GET_SUPERCATEGORY_FAILURE = "GET_SUPERCATEGORY_FAILURE";

export const GET_ExtraItem_BEGIN = "GET_ExtraItem_BEGIN";
export const GET_ExtraItem_SUCCESS = "GET_ExtraItem_SUCCESS";
export const GET_ExtraItem_FAILURE = "GET_ExtraItem_FAILURE";

export const GET_DINTERO_TOKEN_BEGIN = "GET_DINTERO_TOKEN_BEGIN";
export const GET_DINTERO_TOKEN_SUCCESS = "GET_DINTERO_TOKEN_SUCCESS";
export const GET_DINTERO_TOKEN_ERROR = "GET_DINTERO_TOKEN_ERROR";

export const CLEAR_STORAGE = "CLEAR_STORAGE";

export const CHANGE_INSTRUCTION = "CHANGE_INSTRUCTION";
export const EXTRA_INSTRUCTION = "EXTRA_INSTRUCTION"
//  step one dintero
export const PAYMENT_HITTING_BEGIN = "PAYMENT_HITTING_BEGIN";
export const PAYMENT_HITTING_SUCCESS = "PAYMENT_HITTING_SUCCESS";
export const PAYMENT_HITTING_ERROR = "PAYMENT_HITTING_ERROR";
export const RESET_PAYMENT_HITTING = "RESET_PAYMENT_HITTING";

//  step two dintero transaction checking
export const TRANSACTION_CHECK_BEGIN = "TRANSACTION_CHECK_BEGIN";
export const TRANSACTION_CHECK_SUCCESS = "TRANSACTION_CHECK_SUCCESS";
export const TRANSACTION_CHECK_ERROR = "TRANSACTION_CHECK_ERROR";

export const ORDER_TRANSACTION_CHECK_BEGIN = "ORDER_TRANSACTION_CHECK_BEGIN";
export const ORDER_TRANSACTION_CHECK_SUCCESS =
  "ORDER_TRANSACTION_CHECK_SUCCESS";
export const ORDER_TRANSACTION_CHECK_ERROR = "ORDER_TRANSACTION_CHECK_ERROR";

//  getting all categories
export const GET_CATEGORIES_BEGIN = "GET_CATEGORIES_BEGIN";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

export const REMOVE_TABLE_CART_ITEM = "REMOVE_TABLE_CART_ITEM";

// saving the table
export const SAVE_TABLE_BEGIN = "SAVE_TABLE_BEGIN";
export const SAVE_TABLE_SUCCESS = "SAVE_TABLE_SUCCESS";
export const SAVE_TABLE_ERROR = "SAVE_TABLE_ERROR";

export const DELETE_TABLE_BEGIN = "DELETE_TABLE_BEGIN";
export const DELETE_TABLE_SUCCESS = "DELETE_TABLE_SUCCESS";
export const DELETE_TABLE_ERROR = "DELETE_TABLE_ERROR";
//  getting all producsts

export const GET_PRODUCTS_BEGIN = "GET_PRODUCTS_BEGIN";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";

export const REMOVE_PRICE = "remove_price";
export const ADD_PRICE = "add_price";

export const REDUCE_EXTRA_ITEM = "reduce_extra_item";
export const ADD_EXTRA_ITEM = "add_extra_item";
export const ADD_TO_TABLE_CART = "ADD_TO_TABLE_CART";
export const REMOVE_FROM_TABLE_CART = "REMOVE_FROM_TABLE_CART";

// export const ORDER_PAYMENT_CHECK_BEGIN = "ORDER_PAYMENT_CHECK_BEGIN";
// export const ORDER_PAYMENT_CHECK_SUCCESS = "ORDER_PAYMENT_CHECK_SUCCESS";
// export const ORDER_PAYMENT_CHECK_ERROR = "ORDER_PAYMENT_CHECK_ERROR";

export const toggleModal = (product) => ({
  type: TOGGLE_MODAL,
  payload: product
});
export const onlytoggleModal = () => ({
  type: ONLY_TOGGLE_MODAL
});

export const resetModal = () => ({
  type: CLOSE_MODAL
});

export const addQuantity = () => ({
  type: ADD_QUANTITY
});
export const removeQuantity = () => ({
  type: REMOVE_QUANTITY
});
export const addExtraPrice = (extraPrice) => ({
  type: ADD_PRICE,
  payload: extraPrice
});
export const reduceExtraPrice = (extraPrice) => ({
  type: REMOVE_PRICE,
  payload: extraPrice
});
export const reduceExtraItems = (itm) => ({
  type: REDUCE_EXTRA_ITEM,
  payload: itm
});
export const addExtraItems = (itm) => ({
  type: ADD_EXTRA_ITEM,
  payload: itm
});
export const addItems = () => ({
  type: ADD_TO_CART
});

export const getCart = () => ({
  type: GET_CART
  // cart: []
  // cart: ,
  // totalPrice: localStorage.getItem('totalPrice')
  //   ? JSON.parse(localStorage.getItem('totalPrice'))
  //   : 0,
  // totalQuantity: localStorage.getItem('totalQuantity')
  //   ? JSON.parse(localStorage.getItem('totalQuantity'))
  //   : 0
});

export const removeFromCart = (id) => ({
  type: REMOVE_FROM_CART,
  id
});
export const removeExtraFromCart = (ids) => ({
  type: REMOVE_EXTRA_FROM_CART,
  ids
});

export const handleQuantityChange = (value) => ({
  type: CHANGE_QUANTITY,
  value
});

export const deliveryOptionsPopup = (orderType) => ({
  type: TOGGLE_DELIVERY_MODAL,
  orderType
});

export const fetchProductsBegin = () => ({
  type: FETCH_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: { products }
});

export const fetchProductsFailure = (error) => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload: { error }
});

export const saveTableBegin = () => {
  return {
    type: SAVE_TABLE_BEGIN
  };
};

export const saveTableSuccess = (number) => ({
  type: SAVE_TABLE_SUCCESS,
  payload: { number }
});

export const saveTableFailure = (error) => ({
  type: SAVE_TABLE_ERROR,
  payload: { error }
});

export const deleteTableBegin = () => {
  return {
    type: DELETE_TABLE_BEGIN
  };
};

export const deleteTableSuccess = (number) => ({
  type: DELETE_TABLE_SUCCESS,
  payload: { number }
});

export const deleteTableFailure = (error) => ({
  type: DELETE_TABLE_ERROR,
  payload: { error }
});
export const AddTableToCart = (cart) => {
  return { type: ADD_TO_TABLE_CART, payload: cart };
};
export const RemoveTableFromCart = (cart) => {
  return {
    type: REMOVE_FROM_TABLE_CART,
    payload: cart
  };
};

export const removeTableCartItem = (cart) => {
  return {
    type: REMOVE_TABLE_CART_ITEM,
    payload: cart
  };
};

export const payment_and_order_staus = () => {
  return {
    type: PAYMENT_AND_ORDER_STATUS
  };
};

export function fetchProducts(id) {
  return (dispatch) => {
    dispatch(fetchProductsBegin());
    return fetch(`${globalConst.serverip}public/getpcateogories/${id}`)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchProductsSuccess(json.data));
        return json.data;
      })
      .catch((error) => {
        localStorage.clear();
        dispatch(fetchProductsFailure(error));
      });
  };
}
export const getExtraItemBegin = () => ({
  type: GET_ExtraItem_BEGIN
});
export const getExtraItemSuccess = (products) => ({
  type: GET_ExtraItem_SUCCESS,
  payload: { products }
});

export const getExtraItemFailure = (error) => ({
  type: GET_ExtraItem_FAILURE,
  payload: { error }
});
export const getExtraItems = (id) => {
  return (dispatch) => {
    dispatch(getExtraItemBegin());
    return fetch(`${globalConst.serverip}public/getExtraItems/${id}`)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(getExtraItemSuccess(json.result));
        return json.data;
      })
      .catch((error) => {
        
        // dispatch(getExtraItemFailure(error));
      });
  };
};
// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

//res info

export const getRestaurantInfo = (id) => {
  return (dispatch) => {
    dispatch(getRestaurantBegin());
    return fetch(`${globalConst.serverip}public/getpbranchinfo/${id}`)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        
        dispatch(getRestaurantSuccess(json.data));
        return json.data;
      })
      .catch((error) => {
        localStorage.clear();
        dispatch(getRestaurantFailure(error));
      });
  };
};

export const getRestaurantBegin = () => ({
  type: GET_RESTAURANT_BEGIN
});

export const getRestaurantSuccess = (data) => ({
  type: GET_RESTAURANT_SUCCESS,
  data
});

export const getRestaurantFailure = (error) => ({
  type: GET_RESTAURANT_FAILURE,
  error
});

////order

export const checkUser = (email, firstName, lastName, phoneNo, branchId,countryCode) => {
  return (dispatch) => {
    dispatch(checkUserBegin());

    const data = { email, firstName, lastName, phoneNo, branchId,countryCode };
    
    //!TODO
    return fetch(`${globalConst.serverip}public/savecustomer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        
        dispatch(checkUserSuccess(json.data));
        return json.data;
      })
      .catch((error) => {
        localStorage.clear();
        dispatch(checkUserFailure(error));
      });
  };
};

//  getting single super category

export const getsupercategoryBegin = () => ({
  type: GET_SUPERCATEGORY_BEGIN
});
export const getsupercategorySuccess = (data) => ({
  type: GET_SUPERCATEGORY_SUCCESS,
  data
});

export const getsupercategoryFailure = (error) => ({
  type: GET_SUPERCATEGORY_FAILURE,
  error
});

export const getsupercategory = (id) => {
  return (dispatch) => {
    dispatch(getsupercategoryBegin());
    return fetch(`${globalConst.serverip}public/getsupercat/${id}`)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(getsupercategorySuccess(json.data.data));
        return json.data.data;
      })
      .catch((error) => {
        
        // localStorage.clear();
        dispatch(getsupercategoryFailure(error));
      });
  };
};

export const saveTable = (payLoad) => {
  
  return (dispatch) => {
    dispatch(saveTableBegin());

    
    return fetch(`${globalConst.serverip}public/saveonlinetable`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payLoad)
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const tableData = {
          tableNumber: json.table.number,
          tableId: json.table._id
        };
        localStorage.setItem("tableData", JSON.stringify(tableData));

        dispatch(saveTableSuccess(json.table.number));

        return json.table;
      })
      .catch((error) => {
       
        // localStorage.clear();
        dispatch(saveTableFailure(error));
      });
  };
};

export const deleteTable = (slug) => {
  

  return (dispatch) => {
    dispatch(deleteTableBegin());
   

    const tableData = JSON.parse(localStorage.getItem("tableData"));
    if (!tableData) {
      return;
    }
    return fetch(`${globalConst.serverip}public/deleteonlinetable`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: tableData.tableId,
        slug: slug
      })
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        localStorage.removeItem("tableData");

        dispatch(deleteTableSuccess());

        return json;
      })
      .catch((error) => {
      
        // localStorage.clear();
        dispatch(deleteTableFailure(error));
      });
  };
};

export const checkDeliverUser = (
  email,
  firstName,
  lastName,
  phoneNo,
  line1,
  line2,
  city,
  state,
  pin,
  country,
  branchId,
  countryCode,
) => {
  return (dispatch) => {
    dispatch(checkUserBegin());

    const data = {
      email,
      firstName,
      lastName,
      phoneNo,
      branchId,
      countryCode,
      address: {
        line1,
        line2,
        city,
        state,
        pin,
        country
      }
    };
    
    //!TODO
    return fetch(`${globalConst.serverip}public/savecustomer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
      
        dispatch(checkUserSuccess(json.data));
        return json.data;
      })
      .catch((error) => {
        localStorage.clear();
        dispatch(checkUserFailure(error));
      });
  };
};

export const checkUserBegin = () => ({
  type: CHECK_USER_BEGIN
});

export const checkUserSuccess = (user) => ({
  type: CHECK_USER_SUCCESS,
  user
});

export const checkUserFailure = (error) => ({
  type: CHECK_USER_FAILURE,
  payload: { error }
});

export const handleUserFormChange = (name, value) => ({
  type: USER_FORM_CHANGE,
  payload: { name, value }
});

export const handleDeliveryForChange = (name, value) => ({
  type: USER_DELIVER_FORM_CHANGE,
  payload: { name, value }
});

export const editUser = () => ({
  type: EDIT_USER
});

export const placeOnlineOrder = (
  branchId,
  customerId,
  items,
  taxPrice,
  type,
  tax
) => {
  return (dispatch) => {
    var ordertype = "";

    if (type === "deliver") {
      ordertype = "Drive Out";
      dispatch(placeOnlineOrderBegin(ordertype));
    } else {
      ordertype = "Take away";
      dispatch(placeOnlineOrderBegin(ordertype));
    }
    const data = {
      orderType: ordertype,
      items,
      customerId,
      branchId,
      taxPrice,
      cash: taxPrice,
      tax: tax
    };
    return fetch(`${globalConst.serverip}public/createorder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(placeOnlineOrderSuccess(json));
        // return json.data;
      })
      .catch((error) => {
        localStorage.clear();
        dispatch(placeOnlineOrderFailure(error));
      });
  };
};

export const placeTableOnlineOrder = (
  branchId,
  customerId,
  items,
  taxPrice,
  type,
  tax,
  tableData
) => {
  return (dispatch) => {
    var ordertype = type;
    if (!tableData) {
      return;
    }
    /*if (type === "deliver") {
      ordertype = "Drive Out";
      dispatch(placeOnlineOrderBegin(ordertype));
    } else {
      ordertype = "Take away";
      dispatch(placeOnlineOrderBegin(ordertype));
    }*/
    //const tableData = JSON.parse(localStorage.getItem("tableData"));

    const data = {
      orderType: ordertype,
      tax: tax,
      items,
      branchId,
      taxPrice,
      cash: taxPrice,
      tableNumber: tableData.tableNumber,
      tableId: tableData.tableId
    };
    //!TODO
    return fetch(`${globalConst.serverip}public/createTableorder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
      
        //dispatch(placeOnlineOrderSuccess(json));
        dispatch(placeOnlineTableOrderSuccess(json));

        // return json.data;
      })
      .catch((error) => {
        localStorage.clear();
        dispatch(placeOnlineOrderFailure(error));
      });
  };
};

export const placeOnlineOrderBegin = (ordertype) => ({
  type: ONLINE_ORDER_BEGIN,
  ordertype
});

export const placeOnlineOrderSuccess = (data) => ({
  type: ONLINE_ORDER_SUCCESS,
  payload: data
});

export const placeOnlineTableOrderSuccess = (data) => ({
  type: ONLINE_TABLEORDER_SUCCESS,
  payload: data
});

export const placeOnlineOrderFailure = (error) => ({
  type: ONLINE_ORDER_FAILURE,
  payload: { error }
});

export const resetOrderPage = () => ({
  type: RESET_ORDER
});

export const clearStorage = () => {
  // alert("inin");
  return { type: CLEAR_STORAGE };
};

export const handleInstructionChange = (value) => {
  return {
    type: CHANGE_INSTRUCTION,
    value
  };
};
export const handleExtraInstructions =(value)=>{
  
  return {
    type: EXTRA_INSTRUCTION,
    value
  }; 
}

export const get_dintero_token_begin = () => ({
  type: GET_DINTERO_TOKEN_BEGIN
});

export const get_dintero_token_success = (data) => ({
  type: GET_DINTERO_TOKEN_SUCCESS,
  data
});

export const get_dintero_token_error = (error) => ({
  type: GET_DINTERO_TOKEN_ERROR,
  error
});

export const get_dintero_token = (data) => {
  return (dispatch) => {
    var data = {
      grant_type: "client_credentials"
      // audience: "https://api.dintero.com/v1/accounts/T11112863"
    };

    return fetch("https://api.dintero.com/v1/accounts/T11112863/auth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic NmYwZGU5Y2MtZTAwMi00NTdkLTllYTUtNmFhYTg0M2QwNjAyOjIzNjM5YWZjLTY3OTEtNGZmNC1hY2RmLTQ4Nzk3MjUyYzUyZg=="
      },
      body: JSON.stringify(data)
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        

        // dispatch(placeOnlineOrderSuccess(json.data));
        // return json.data;
      })
      .catch((error) => {
        
        // localStorage.clear();
        // dispatch(placeOnlineOrderFailure(error));
      });
  };
};

export const payment_hitting_begin = () => ({
  type: PAYMENT_HITTING_BEGIN
});
export const payment_hitting_success = (data) => ({
  type: PAYMENT_HITTING_SUCCESS,
  data
});
export const payment_hitting_error = (error) => ({
  type: PAYMENT_HITTING_ERROR,
  error
});
export const payment_hitting = (id, totalPrice, items) => {
  var payLoad = {
    id: id,
    totalPrice: totalPrice,
    items: items
  };
  let endPoint;
  if((localStorage.getItem("Vipps"))=="true"){
    endPoint="payment1"
  }else{
    endPoint="payment"
  }
  return (dispatch) => {
  
    dispatch(payment_hitting_begin());
    return fetch(`${globalConst.serverip}public/${endPoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payLoad)
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((data) => {
       
        dispatch(payment_hitting_success(data));
        // return data;
      })
      .catch((error) => {
        localStorage.clear();
        dispatch(payment_hitting_error(error));
      });
  };
};

export const tableOrder_payment_hitting = (id, totalPrice, items) => {
  let endPoint;
  if((localStorage.getItem("Vipps"))=="true"){
    endPoint="tableorderpaymentvipps"
  }else{
    endPoint="tableorderpayment"
  }
  var payLoad = {
    id: id,
    totalPrice: totalPrice,
    items: items
  };
  return (dispatch) => {
    dispatch(payment_hitting_begin());
    return fetch(`${globalConst.serverip}public/${endPoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payLoad)
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((data) => {
      
        dispatch(payment_hitting_success(data));
        // return data;
        // localStorage.removeItem("tableData");
      })
      .catch((error) => {
        localStorage.clear();
        dispatch(payment_hitting_error(error));
      });
  };
};

export const transaction_check_begin = () => ({
  type: TRANSACTION_CHECK_BEGIN
});
export const transaction_check_success = (data) => ({
  type: TRANSACTION_CHECK_SUCCESS,
  data
});
export const transaction_check_error = (error) => {
 

  return {
    type: TRANSACTION_CHECK_ERROR,
    error
  };
};

export const transaction_check = (id) => {
  var postData = {
    id: id
  };
  return (dispatch) => {
    dispatch(transaction_check_begin());
    return (
      fetch(`${globalConst.serverip}public/transactioncheck`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
      })
        // .then(handleErrors)
        .then((res) => res.json())
        .then((json) => {
          dispatch(transaction_check_success(json.data));
          // return json.data;
        })
        .catch((error) => {
       
          // localStorage.clear();
          dispatch(transaction_check_error(error));
        })
    );
  };
};
export const table_order_transaction_check = (data) => {
  
  return async (dispatch) => {
    dispatch(order_transaction_check_begin());
    console.log('Data sending-->', data);

    if(data.paymentType == 'Tidy'){

      let tidyPayResponse = data.tidyPayResponse;

      //tableorderpaymentcheck
      let responseType  = tidyPayResponse.responseType;
      let responseCode  = tidyPayResponse.responseCode;
      let transactionId = tidyPayResponse.transactionId;
      let accountId = tidyPayResponse.accountId;
      let cycleId = tidyPayResponse.cycleId;

      let transactionObj = {};

      if(responseType && responseCode == 'A01'){
        transactionObj = {
          cycleId: cycleId,
          status: 'AUTHORIZED',
          account_id: accountId,
          transaction_id: transactionId,
          vendorCode: "47001", ///this should be dynamic
          vendorName: "TDIPay",
          card:{          
            masked_pan: tidyPayResponse.accountNumberMasked,
            expiry_date: tidyPayResponse.accountAccessory,
            brand: "xxxx",
            type:  "xxxx"
          }
        };
  
        let formData = {
          id: data.id,
          paymentType: data.paymentType,
          tidyCycleId: cycleId,
          transactionObj: transactionObj
        };
  
        const response = await axios.post(
          `${globalConst.serverip}public/tableorderpaymentcheck`,
          formData,
          {
            headers: {
                "Content-Type": "application/json"
            }
          }
        );
  
        if (response.data.status == 200) {
            console.log("response", response);
            //setLoading(false);
            dispatch(order_transaction_check_success(response));
            //console.log("response after payment confirm in TidyPay", response);
        } else {
            // setLoading(false);
            // setIsError(true);
            dispatch(order_transaction_check_error(response.message));
        }
      } else {

        dispatch(order_transaction_check_error("UNAUTHORIZED"));

      }

    } else {
    return (
      fetch(`${globalConst.serverip}public/tableorderpaymentcheck`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        // .then(handleErrors)
        .then((res) => res.json())
        .then((json) => {
         
          if (json.status == 200) {
            dispatch(order_transaction_check_success(json));
          } else {
            dispatch(order_transaction_check_error(json.message));
          }
          // return json.data;
        })
        .catch((error) => {
         
          dispatch(order_transaction_check_error(error));
        })
    );
    } 
  }
};

export const order_transaction_check_begin = () => ({
  type: ORDER_TRANSACTION_CHECK_BEGIN
});
export const order_transaction_check_success = (data) => ({
  type: ORDER_TRANSACTION_CHECK_SUCCESS,
  data
});
export const order_transaction_check_error = (error) => ({
  type: ORDER_TRANSACTION_CHECK_ERROR,
  error
});

export const order_transaction_check = (data) => {
  return (dispatch) => {
    dispatch(order_transaction_check_begin());
    fetch(`${globalConst.serverip}public/orderpaymentcheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      // .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        
        dispatch(order_transaction_check_success(json));
        // return json.data;
      })
      .catch((error) => {
    
        // localStorage.clear();
        dispatch(order_transaction_check_error(error));
      });
  };
};

export const treeDrive_order_transaction_check = (data) => {
  return (dispatch) => {
    dispatch(order_transaction_check_begin());
    fetch(`${globalConst.serverip}api/v1/treeDrive/treedrivepayment/orderpaymentcheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      // .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        
        dispatch(order_transaction_check_success(json));
        // return json.data;
      })
      .catch((error) => {
    
        // localStorage.clear();
        dispatch(order_transaction_check_error(error));
      });
  };
};

export const get_categories_begin = () => ({
  type: GET_CATEGORIES_BEGIN
});
export const get_categories_success = (data) => ({
  type: GET_CATEGORIES_SUCCESS,
  data
});
export const get_categories_error = (error) => ({
  type: GET_CATEGORIES_ERROR,
  error
});

export const get_categories = (slug) => {
  return (dispatch) => {
    dispatch(get_categories_begin());
    fetch(`${globalConst.serverip}public/alcat/${slug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
      // body: JSON.stringify(data)
    })
      // .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        
        dispatch(get_categories_success(json));
        // return json.data;
      })
      .catch((error) => {
        
        // localStorage.clear();
        dispatch(get_categories_error(error));
      });
  };
};

export const getPlu1Table_categories = (slug) => {
  return (dispatch) => {
    dispatch(get_categories_begin());
    fetch(`${globalConst.serverip}public/tableorder/${slug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
      // body: JSON.stringify(data)
    })
      // .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        //console.log("categories details", json);
        dispatch(get_categories_success(json));
        // return json.data;
      })
      .catch((error) => {
        
        // localStorage.clear();
        dispatch(get_categories_error(error));
      });
  };
};

export const get_products_begin = () => ({
  type: GET_PRODUCTS_BEGIN
});
export const get_products_success = (data) => {
  return {
    type: GET_PRODUCTS_SUCCESS,
    data
  };
};

export const get_products_error = (error) => ({
  type: GET_PRODUCTS_ERROR,
  error
});
export const get_products = (id) => {
  return (dispatch) => {
    dispatch(get_products_begin());
    fetch(`${globalConst.serverip}public/catprod/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
      // body: JSON.stringify(data)
    })
      // .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
       
        dispatch(get_products_success(json));
        // return json.data;
      })
      .catch((error) => {
      
        // localStorage.clear();
        dispatch(get_products_error(error));
      });
  };
};
export const get_plu1Table_products = (id) => {
  return (dispatch) => {
    dispatch(get_products_begin());
    fetch(`${globalConst.serverip}public/tabcatprod/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
      // body: JSON.stringify(data)
    })
      // .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(get_products_success(json));
        // return json.data;
      })
      .catch((error) => {
    
        // localStorage.clear();
        dispatch(get_products_error(error));
      });
  };
};

export const PAYMENT_CONFIRM_START="PAYMENT_CONFIRM_START";
export const PAYMENT_CONFIRM_SUCCESS="PAYMENT_CONFIRM_SUCCESS";
export const PAYMENT_CONFIRM_FAIL="PAYMENT_CONFIRM_FAIL"
export const paymentConfirmAction =(data)=>async(dispatch)=>{
    try {
      
      dispatch({
        type:PAYMENT_CONFIRM_START,
        payload:{}
      })
      const response = await  axios.post(`${globalConst.serverip}public/orderPaymentCheckVippsOnline`,data,{
        headers:{
          "Content-Type": "application/json"
        }
      })
     

     
        dispatch({
          type:PAYMENT_CONFIRM_SUCCESS,
          payload:response.data
        })
  
    } catch (error) {
      
      dispatch({
        type:PAYMENT_CONFIRM_FAIL,
        payload:{message: "UNAUTHORIZED",
        otp: "",
        status: 400}
      })

      
    }

}
export const paymentConfirmActionTable =(data)=>async(dispatch)=>{
  
  try {
   
    dispatch({
      type:PAYMENT_CONFIRM_START,
      payload:{}
    })
   const response = await  axios.post(`${globalConst.serverip}public/tableOrderPaymentCheckVipps`,data,{
      headers:{
        "Content-Type": "application/json"
      }
    })
    

   
      dispatch({
        type:PAYMENT_CONFIRM_SUCCESS,
        payload:response.data
      })

  } catch (error) {
  
    dispatch({
      type:PAYMENT_CONFIRM_FAIL,
      payload:{message: "UNAUTHORIZED",
      otp: "",
      status: 400}
    })

    
  }

}

export const clearPaymentData =()=>async(dispatch)=>{
  
  try {

    dispatch({
      type:'CLEAR',
      payload:{}
    })
  

  } catch (error) {
  
    

    
  }

}