import React from "react";
import {
  Navbar,
  NavItem,
  Collapse,
  NavbarToggler,
  Nav,
  NavbarBrand
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import globalConst from "../helpers/global";
import { closeModal } from "../actions";

const Header = ({
  dispatch,
  isOpen,
  toggle,
  name,
  email,
  phoneNo,
  logo,
  id
}) => {
  return (
    <Navbar color="light" light expand="md">
      <div className="container">
        <Link to={`/restaurant/${id}`}>
          <NavbarBrand>
            <img
              src={`${globalConst.serverip}${logo}`}
              alt="logo"
              className="res-logo"
            />
          </NavbarBrand>
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem style={{ textTransform: "capitalize" }}>{name}</NavItem>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};

const mapStateToProps = ({ restaurant }) => {
  const { name, email, logo, phoneNo, id } = restaurant;
  
  return {
    name,
    email,
    logo,
    phoneNo,
    id
  };
};
export default connect(mapStateToProps)(Header);
