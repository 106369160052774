import React, { useEffect, useState } from "react";
import {  Modal,ModalBody } from "reactstrap";
import Spinner from "reactstrap/lib/Spinner";

const ModelComp = ({ isTrue }) => {
  const [model, setModel] = useState(false);

  useEffect(
    () => {
      setModel(isTrue);
    },
    [isTrue]
  );
  return (
    <div style={{ margin: 0 }}>
      <Modal funk={true} isOpen={model} centered={true}>
        <ModalBody className="modalContainer">
          Please wait and dont press back button...
          <Spinner
            style={{ display: "block", margin: "auto", padding: "20px" }}
          >
            Loading...
          </Spinner>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModelComp;
