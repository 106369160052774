import {
  GET_RESTAURANT_BEGIN,
  GET_RESTAURANT_SUCCESS,
  GET_RESTAURANT_FAILURE
} from "../actions/index";

const initialState = {
  error: null,
  loading: false,
  name: "",
  logo: "",
  email: "",
  phoneNo: "",
  isQrAllow: false,
  isQrAllowByRest:false
};

const restaurant = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESTAURANT_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        email: action.data.email,
        name: action.data.name,
        logo: action.data.logo,
        phoneNo: action.data.phoneNo,
        id: action.data._id,
        slug: action.data.slug,
        driveoutCharge: action.data.driveoutCharge,
        availability: action.data.availability,
        monday: action.data.monday,
        tuesday: action.data.tuesday,
        wednesday: action.data.wednesday,
        thursday: action.data.thursday,
        friday: action.data.friday,
        saturday: action.data.saturday,
        sunday: action.data.sunday,
        additional: action.data.additional,
        categoryBackColor: action.data.colors.categoryBackColor,
        colors: action.data.colors,
        taxes: action.data.taxes,
        loading: false,
        isQrAllow: action.data.isQrAllow,
        isQrAllowByRest: action.data.isQrAllowByRest,
        address: action.data.address.city,
        webpayment: action.data.webpayment,
        isTreeDriveAllowed: action.data.isTreeDriveAllowed,
        availableSeats:action.data.availSeats        ,
        err_status: ''
      };
    case GET_RESTAURANT_FAILURE:
      return {
        state,
        loading: false,
        error: action.error,
        err_status: 404
      };
    default:
      return state;
  }
};

export default restaurant;
