import React, { Component } from "react";
// import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { transaction_check } from "../actions";
// import { faUserLock } from "@fortawesome/free-solid-svg-icons";

class Paymentcheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_id: ""
    };
  }
  componentDidMount() {
    var rr = this.useQuery();
    this.setState({ transaction_id: rr });
    this.checkTransaction(rr);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.transactionCheckSuccess !== this.props.transactionCheckSuccess
    ) {
      
      alert("Payment Successfull");
    }
  }
  useQuery() {
    var quey = queryString.parse(this.props.location.search);
    // this.setState({ transaction_id: quey.transaction_id });
    return quey.transaction_id;
  }
  checkTransaction = (rr) => {
    var { dispatch } = this.props;
    dispatch(transaction_check(rr));
  };
  render() {
    // let qry = this.useQuery();
    return (
      <div>
        <p>{this.state.transaction_id}</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  transactionCheckLoading: state.order.transactionCheckLoading,
  transactionCheckSuccess: state.order.transactionCheckSuccess,
  transactionCheckError: state.order.transactionCheckError,
  transactionCheckData: state.order.transactionCheckData
});

export default connect(mapStateToProps)(Paymentcheckout);
