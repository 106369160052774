import React, { useEffect, useState } from "react";
import axios from "axios";

import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import globalConst from "../helpers/global";
import queryString from "query-string";
import loadingGif from "../assets/loading-gif.gif";
import wentWrong from "../assets/error-gif.gif";
import paySuccess from "../assets/success.gif";

const ReserveTableFallbackTidy = (props) => {

  const useQuery = ()=>{
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const { id } = useParams();

//   const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(async () => {

    try {

      let responseType  = query.get("responseType");
      let responseCode  = query.get("responseCode");
      let transactionId = query.get("transactionId");
      let accountId = query.get("accountId");
      let cycleId = query.get("cycleId");

       // let responseType = searchParams.get('responseType');
        
        let transactionCancelled = query.get("error");   

        let transactionObj = {};
        let paymentType = 'TDIPay';

        ///Check if response type is sale & response code is A01
        ///A01 = success
        if(responseType && responseCode == 'A01'){
          
            transactionObj = {
                cycleId: cycleId,
                status: 'AUTHORIZED',
                account_id: accountId,
                transaction_id: transactionId,
                vendorCode: "47001", ///this should be dynamic
                vendorName: "TDIPay"
            };

        }

        let formData = {
          tidyCycleId: cycleId,
          transactionObj: transactionObj
        };

        const response = await axios.post(
            `${globalConst.serverip}api/v1/public/transactioncheckreservetabletidy`,
            formData,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );
        if (response.data.status == 200) {
            console.log("response", response);
            setLoading(false);
            console.log("response after payment confirm in TidyPay", response);
        } else {
            setLoading(false);
            setIsError(true);
        }
    } catch (error) {
      setLoading(false);
      setIsError(true);
    }
  }, []);

  return loading ? (
    <div className="loading-gif">
      {/* <h3>Loading....</h3> */}
      <img src={loadingGif} alt="Loading" width="80px" />
    </div>
  ) : isError ? (
    <div className="error-page d-flex flex-column justify-content-center align-items-center">
      <img src={wentWrong} alt="Loading" width="150px" />
      <h3>Something went wrong</h3>
    </div>
  ) : (
    <div className="success-payment d-flex flex-column justify-content-center align-items-center">
      <img src={paySuccess} alt="Loading" width="250px" />
      <h3>Payment Successful!</h3>
      <p>
        Thankyou for the Payment. You'll get the confirmation SMS or E-Mail from
        the Restaurant soon.
      </p>
    </div>
  );
};

export default ReserveTableFallbackTidy;
