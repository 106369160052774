import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import "../components/font.css";
import { checkUser, handleUserFormChange, editUser } from "../actions";
import { connect } from "react-redux";
import countryCodes from "country-codes-list";
import "./font.css";
import { Select } from "antd";
var mailcheck = false;
var fnCheck = false;
var lnCheck = false;
var numberCheck = false;
var forminvalid = true;
const PickupForm = ({
  userChecked,

  firstName,

  lastName,
  email,
  phoneNo,
  branchId,
  slug,
  dispatch
}) => {
  const [countryCode, setcountryCode] = useState(47);
  const myCountryCodesObject = countryCodes.customList(
    "countryCode",
    "[{countryCode}]:{countryCallingCode}"
  );
  const allCountryCodes = Object.values(myCountryCodesObject);

  const findUser = (e) => {
    e.preventDefault();
    if (mailcheck == false) {
      dispatch(
        checkUser(email, firstName, lastName, phoneNo, branchId, countryCode)
      );
    }
  };

  const countryCodeChanged = (e) => {
   
    let code = e.target.value.split(":")[1];
    setcountryCode(code);
  };

  const change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleUserFormChange(name, value));
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let al = /^[ a-zA-ZøØæÆåÅ ]+$/;
    let nu = /^[0-9]{8,10}$/;
    if (name == "email") {
      if (value && re.test(value) && name == "email") {
        mailcheck = false;
        forminvalid = false;
      } else {
        forminvalid = true;
        mailcheck = true;
      }
    } else if (name == "firstName") {
      if (value && al.test(value)) {
        forminvalid = false;
        fnCheck = false;
      } else {
        fnCheck = true;
        forminvalid = true;
      }
    } else if (name == "lastName") {
      if (value && al.test(value)) {
        lnCheck = false;
        forminvalid = false;
      } else {
        lnCheck = true;
        forminvalid = true;
      }
    } else if (name == "phoneNo") {
      if (value && nu.test(value)) {
        numberCheck = false;
        forminvalid = false;
      } else {
        numberCheck = true;
        forminvalid = true;
      }
    }
  };

  const edit = () => {
    dispatch(editUser());
  };

  return (
    <Card className="checkout-card" style={{height:"80vh"}}>
      <CardHeader>
        Kundeinformasjon
        {userChecked && (
          <button className="link-button float-right" onClick={edit}>
            Edit
          </button>
        )}
      </CardHeader>
      <CardBody className="bgCard">
        <Form onSubmit={(e) => findUser(e)}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="firstNname">Fornavn</Label>
                <Input
                  type="text"
                  autocomplete="off"
                  autosuggest="off"
                  name="firstName"
                  id="firstName"
                  invalid={fnCheck}
                  placeholder="Fornavn"
                  value={firstName}
                  onChange={change}
                  className="textField"
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="lastNname">Etternavn</Label>
                <Input
                  type="text"
                  autosuggest="off"
                  autocomplete="off"
                  name="lastName"
                  id="lastName"
                  invalid={lnCheck}
                  placeholder="Etternavn"
                  value={lastName}
                  className="textField"
                  onChange={change}
                  disabled={userChecked}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="email">E-Post</Label>
                <Input
                  type="email"
                  autosuggest="off"
                  autocomplete="off"
                  name="email"
                  id="email"
                  className="textField"
                  invalid={mailcheck}
                  valid={mailcheck}
                  placeholder="E-post"
                  value={email}
                  onChange={change}
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>
            {/* <div className=""> */}
            <Col md={3}>
              <FormGroup>
                <Label for="select">Landskode</Label>
                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                  className="textField"
                  onChange={(e) => countryCodeChanged(e)}
                  defaultValue={"[BV]:47"}
                >
                  {allCountryCodes &&
                    allCountryCodes.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="phoneNo">Telefon</Label>
                <Input
                  type="text"
                  name="phoneNo"
                  autosuggest="off"
                  autocomplete="off"
                  id="phoneNo"
                  invalid={numberCheck}
                  placeholder="Telefon"
                  value={phoneNo}
                  className="textField"
                  onChange={change}
                  maxLength="10"
                  minLength="8"
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>

            {/* </div> */}
          </Row>
          {!userChecked && (
            <Button
              type="submit"
              disabled={forminvalid}
              color="success"
              className="lagreBtn"
              outline
            >
              Lagre
              {/* Submit */}
            </Button>
          )}
        </Form>
        <p
          style={{ color: "grey", marginTop: 10, float: "right", fontSize: 13 }}
        >
          Se våre :
          {/* <a href="https://web.a-board.world/merchantterm" target="_blank">
            {" "}
            <u>Salgsvilkår og betingelser</u>
          </a> */}
          <Link to={"/restaurantterm/" + slug} className="termsLink">Salgsvilkår og betingelser</Link>
        </p>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = ({ order, restaurant }) => {
  let branchId = restaurant.id;
  let slug = restaurant.slug;
  const {
    email,
    phoneNo,
    firstName,
    lastName,
    userChecked,
    activeId,
    otp
  } = order;
  return {
    email,
    phoneNo,
    firstName,
    lastName,
    userChecked,
    activeId,
    otp,
    branchId,
    slug
  };
};

export default connect(mapStateToProps)(PickupForm);
