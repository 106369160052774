import React, { useEffect, useState } from "react";
import table from "../../src/assets/table.jpg";
import {
  Spin,
  notification,
  DatePicker,
  DatePickerProps,
  TimePicker
} from "antd";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row
} from "reactstrap";
import globalConst from "../helpers/global";
import { getRestaurantInfo } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { number } from "prop-types";
import thinking from "../assets/thinking.gif";
import axios from "axios";
import bgbook from "../assets/bgbook.jpg";
import { validatePhone, validEmail, validName } from "../helpers/regex";
import moment from "moment";
import countryCodesList from "country-codes-list";
import NotFoundGif from "../assets/notfoundgif.gif";
import TextArea from "antd/lib/input/TextArea";
const myCountryCodesObject = countryCodesList.customList(
  "countryCode",
  "[{countryCode}]:{countryCallingCode}"
);

const allCountryCodes = Object.values(myCountryCodesObject);

const ReserveTable = (props) => {
  const slug = props.match.params.slug;
  const [loading, setLoading] = useState(false);
  const [countryCode, setcountryCode] = useState(47);

  const [numberOfSeats, setNumberOfSeats] = useState(1);
  const [numberOfSeatsChildren, setNumberOfSeatsChildren] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [reserveTableId, setReserveTableId] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [price, setPrice] = useState();

  let history = useHistory();
  const dispatch = useDispatch();
  const format = "HH:mm";
  const [formObject, setFormObject] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    date: "",
    time: "",
    note: ""
  });

  const [formError, setFormError] = useState({});

  const { id, logo, name, additional, error, err_status,availableSeats } = useSelector(
    (state) => state.restaurant
  );
  const openNotification = (message, desc) => {
    notification.open({
      message: message,
      description: desc,
    });
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      console.log("no errors");
      setLoading(true);
      const data = {
        ...formObject,
        branchId: id,
        numberOfSeats,
        countryCode,
        numberOfSeatsChildren
      };
      reserveTable(`${globalConst.serverip}api/v1/public/reservetable`, data);
    }
  }, [formError]);

  async function getReserVePrice() {
    try {
      let response = await fetch(
        `${globalConst.serverip}api/v1/admin/branch/reservetableprice`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong getting price");
      }
      console.log("helooppp", response);

      if (response.status == 200) {
        let res = await response.json();
        console.log("helooppp");
        setPrice(res.data.price);
      }
    } catch (error) {
      setPrice("0");
    }
  }

  useEffect(() => {
    dispatch(getRestaurantInfo(slug));
    getReserVePrice();
  }, []);

  useEffect(() => {
    console.log('---reserve table id--', reserveTableId, price)
    
    if(reserveTableId && price < 1){
      payWithTidyPay()
    }
    
  }, [reserveTableId, price]);
  const reserveTable = async (url, data) => {
    try {
      const res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      console.log("res", res);
      if (res.data.status == 200) {
        setReserveTableId(res.data.data.id);
        setPrice(res.data.data.price);
        
        if(res.data.data.price > 0){
        setIsVisible(true);
        } 
        setLoading(false);
      } else {
        setIsVisible(false);
        setLoading(false);
        openNotification("Error", res.data.message);
      }
    } catch (error) {
      console.log("eroor is", error);
      setLoading(false);
    }
  };

  const validateForm = (values) => {
    const errors = {};

    if (values.firstName === "") {
      errors.firstName = "*Please enter first name";
    } else if (!validName.test(values.firstName)) {
      errors.firstName = "*Please enter a valid first name";
    }

    if (values.lastName === "") {
      errors.lastName = "*Please enter last name";
    } else if (!validName.test(values.lastName)) {
      errors.lastName = "*Please enter a valid last name";
    }

    if (values.phoneNumber === "") {
      errors.phoneNumber = "*Please enter your phone number";
    } else if (!validatePhone.test(values.phoneNumber)) {
      errors.phoneNumber = "*Please enter a valid phone number";
    }

    if (values.email === "") {
      errors.email = "*Please enter email address";
    } else if (!validEmail.test(values.email)) {
      errors.email = "*Please provide a valid email address";
    }

    if (values.date === "") {
      errors.date = "*Please enter  date";
    } else {
      const today = new Date();
      var d1 = new Date(moment().subtract(1, "days"));
      var d2 = new Date(values.date);
      if (d1.getTime() > d2.getTime()) {
        errors.date = "*Please enter  a valid date";
      }
    }

    if (values.time === "") {
      errors.time = "*Please select time";
    }
    return errors;
  };

  const submitForm = (e) => {
    // console.log("SubmitForm is working")
    e.preventDefault();
    setFormError(validateForm(formObject));
    setIsSubmit(true);
  };

  const handleInputChange = (e) => {
    console.log(e);
    let value = e.target.value;

    setFormObject({ ...formObject, [e.target.name]: value });
  };

  const handleDateChange = (date, dateString) => {
    console.log(date, dateString);
    //console.log(e);
    //let value = e.target.value;

    setFormObject({ ...formObject, date: date });
  };

  const timeChanged = (time, timeString) => {
    console.log("time String", timeString, "time", time);
    setFormObject({ ...formObject, ["time"]: timeString });
  };

  const handleOk = () => {};
  const handleCancel = () => {};
  /*  const payWithVippsClicked = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${globalConst.serverip}api/v1/public/initiatePaymentVipps`,
        { id: reserveTableId },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      console.log("response", response);
      window.open(response.data.url, "_self");
      setLoading(false);
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  };*/
  const payWithDinterroClicked = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${globalConst.serverip}api/v1/public/dinterroPaymentReserveTable`,
        { id: reserveTableId },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      console.log("response", response);
      window.open(response.data.url, "_self");
      setLoading(false);
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  };


  const payWithTidyPay = async () => {
    console.log("---Table ID is:---", reserveTableId);
   // return;
    try {
      setLoading(true);
      const response = await axios.post(
        `${globalConst.serverip}api/v1/public/processpayment`,
        { id: reserveTableId },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      console.log("response", response);
      //return; 
      window.open(response.data.url, "_self");
      setLoading(false);
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  };

  const chechAvailSeats=(type)=>{
    if(type=="adult"){
    (numberOfSeats +numberOfSeatsChildren)<availableSeats? 
    setNumberOfSeats(numberOfSeats + 1):  openNotification("Beklager", `Kun ${availableSeats} sitteplasser igjen `)
  }else{
    (numberOfSeats +numberOfSeatsChildren)<availableSeats? setNumberOfSeatsChildren(numberOfSeatsChildren + 1): openNotification("Beklager", `Kun ${availableSeats} sitteplasser igjen `)
  }
  }
  const onCountryCodeSelectHandler = (e) => {
    let code = e.target.value.split(":")[1];
    setcountryCode(code);
  };

  const getRestaurantList = () => {
    alert("hellow");
  };

  function handleBack() {
    history.goBack();
  }
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center main-container"
    >
      {(err_status == 404 || additional && additional.reserveTablePermission == false) && (
        <Row className="d-flex justify-content-between">
          <div className="w-100 d-flex justify-content-center align-items-center pb-3">
            <img src={NotFoundGif} alt="Page Not Found" width="600px" />
          </div>
        </Row>
      )}

      {err_status != 404 && additional && additional.reserveTablePermission && (
        <Row className="d-flex flex-column align-items-center" id="reserveTable">
        {" "}
          {isMobile && (
            <div
              className="w-100 d-flex align-items-center justify-content-end
            "
            >
              <button onClick={handleBack}>X</button>
            </div>
          )}
          <div className="text-center reserveHead">
            <img src={`${globalConst.s3url}${logo}`} alt="Restaurant Logo" height="140px" />
            <h5 className="text-center">{name}</h5>
            <h2 className="text-center">RESERVER BORD</h2>
            <h4 className="text-center">{availableSeats? `Total sitte plasser : ${availableSeats}` :""}</h4>
            
          <div className="d-flex justify-content-center add-btn pb-3">
              <div className="d-flex justify-content-between align-items-center p-2">
                {/* <span>Antall personer</span> */}
                <h4 className="mb-0 pr-3">Voksen</h4>
                <div className="d-flex align-items-center justify-content-center" style={{fontSize:'25px'}}>
                  <button
                    onClick={() =>
                      numberOfSeats > 1 && setNumberOfSeats(numberOfSeats - 1)
                    }
                  >
                    -
                  </button>{" "}
                  &nbsp; {numberOfSeats} &nbsp;
                  <button onClick={() =>availableSeats? chechAvailSeats("adult"): setNumberOfSeats(numberOfSeats + 1)}>
                    +
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center p-2">
                {/* <span>Antall barn</span> */}
                <h4 className="mb-0 px-3">Barn</h4>
                <div className="d-flex align-items-center justify-content-center" style={{fontSize:'25px'}}>
                  <button
                    onClick={() =>
                      numberOfSeatsChildren > 0 &&
                      setNumberOfSeatsChildren(numberOfSeatsChildren - 1)
                    }
                  >
                    -
                  </button>{" "}
                  &nbsp; {numberOfSeatsChildren} &nbsp;
                  <button
                    onClick={() =>
                      availableSeats? chechAvailSeats("child"): setNumberOfSeatsChildren(numberOfSeatsChildren + 1)
                    }
                  >
                    +
                  </button>
                </div>
              </div>
          </div>
          
          <div style={{width: '100%'}}>
            <div className="main-row d-flex justify-content-between pb-1 w-100">
              <FormGroup className="mb-sm-0 d-flex justify-content-between">
                <Label for="FIRSTNAME">
                  Fornavn <span className="text-danger">*</span>{" "}
                </Label>
                <Input
                  className={formError.firstName && "reserveInput"}
                  type="text"
                  name="firstName"
                  id="FIRSTNAME"
                  value={formObject.firstName}
                  onChange={handleInputChange}
                />
                {formError.firstName && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.firstName}
                  </p>
                )}
              </FormGroup>

              <FormGroup className=" mb-sm-0 d-flex justify-content-between">
                <Label for="LASTNAME">
                  Etternavn <span className="text-danger">*</span>
                </Label>
                <Input
                  className={formError.lastName && "reserveInput"}
                  type="text"
                  name="lastName"
                  id="LASTNAME"
                  placeholder=""
                  value={formObject.lastName}
                  onChange={handleInputChange}
                />
                {formError.lastName && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.lastName}
                  </p>
                )}
              </FormGroup>
            </div>

            <div className="main-row d-flex justify-content-between pb-1 pt-1 w-100">
              <FormGroup className="mb-sm-0 d-flex justify-content-between">
                <Label for="=Number">
                  Telefon <span className="text-danger">*</span>
                </Label>
                <div className="d-flex">
                  <Input
                    type="select"
                    name="select"
                    id="selectField"
                    onChange={onCountryCodeSelectHandler}
                    defaultValue={"[BV]:47"}
                  >
                    {allCountryCodes.map((cn) => {
                      return <option value={cn}>{cn.split(":")[1]}</option>;
                    })}
                  </Input>
                  <Input
                    className={formError.phoneNumber && "reserveInput"}
                    type="text"
                    name="phoneNumber"
                    id="PHONENUMBER"
                    placeholder=""
                    value={formObject.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
                {formError.phoneNumber && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.phoneNumber}
                  </p>
                )}
              </FormGroup>

              <FormGroup className="mb-sm-0 d-flex justify-content-between">
                <Label for="emailId">
                  E-Post <span className="text-danger">*</span>
                </Label>
                <Input
                  className={formError.email && "reserveInput"}
                  type="text"
                  name="email"
                  id="emailId"
                  placeholder=""
                  value={formObject.email}
                  onChange={handleInputChange}
                />
                {formError.email && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.email}
                  </p>
                )}
              </FormGroup>
            </div>

            <div className="main-row d-flex justify-content-between pb-1 pt-1 w-100">
              <FormGroup className="mb-sm-0 d-flex justify-content-between">
                <Label for="exampleDate">
                  Dato <span className="text-danger">*</span>
                </Label>

                <Input
                  className={formError.date && "reserveInput"}
                  type="date"
                  name="date"
                  id="exampleDate"
                  placeholder=""
                  value={formObject.date}
                  onChange={handleInputChange}
                />
                {formError.date && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.date}
                  </p>
                )}
              </FormGroup>

              <FormGroup className="mb-sm-0 d-flex justify-content-between">
                <Label for="exampleTime">
                  Tid <span className="text-danger">*</span>
                </Label>
                <Input
                  className={formError.time && "reserveInput"}
                  type="time"
                  name="time"
                  id="exampleTime"
                  placeholder=""
                  value={formObject.time}
                  onChange={handleInputChange}
                />
                {/* <TimePicker  onChange={timeChanged} format={format} value={formError.time} name="time"/> */}
                {formError.time && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.time}
                  </p>
                )}
              </FormGroup>
            </div>

            <div className="main-row d-flex pb-1 pt-1 w-100">
              <FormGroup className="mb-sm-0 w-100 d-flex justify-content-between">
                <Label for="Note">Merk</Label>
                <TextArea
                  type="textarea"
                  name="note"
                  id="Note"
                  autoSize={{ minRows: 4, maxRows: 6 }}
                  maxLength="500"
                  value={formObject.note}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </div>
            {/* <p className="text-center pt-2">
              * Lorem ipsum dolor sit amet consectetur. Vitae et amet non etiam
              donec. Amet ullamcorper a lectus sit maecenas adipiscing.
            </p> */}

            <Button type="submit" className="sendBooking mt-3" onClick={submitForm}>
                Send Bestilling
              </Button>
          </div>
          
          </div>




          {/* <Col xs="12" sm="12" md="3" lg="3" padding="10" className="img-box">
          <div className="d-flex justify-content-center align-items-center">
            {" "}
            <img src={thinking} alt="Thinking Person" width="200px" />
          </div>

          <div className="d-flex flex-column add-btn mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <span>Antall personer</span>
              <div style={{ display: "flex" }}>
                <button
                  onClick={() =>
                    numberOfSeats > 1 && setNumberOfSeats(numberOfSeats - 1)
                  }
                >
                  -
                </button>{" "}
                &nbsp;
                {numberOfSeats} &nbsp;
                <button onClick={() => setNumberOfSeats(numberOfSeats + 1)}>
                  +
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <span>Antall barn</span>
              <div style={{ display: "flex" }}>
                <button
                  onClick={() =>
                    numberOfSeatsChildren > 0 &&
                    setNumberOfSeatsChildren(numberOfSeatsChildren - 1)
                  }
                >
                  -
                </button>{" "}
                &nbsp;
                {numberOfSeatsChildren} &nbsp;
                <button
                  onClick={() =>
                    setNumberOfSeatsChildren(numberOfSeatsChildren + 1)
                  }
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </Col>
 */}



{/* 
        <Col
          md="9"
          className="d-flex justify-content-center align-items-center"
        >
          <Form onSubmit={submitForm} inline className="w-100 px-5">
            <div className="w-100 d-flex justify-content-center align-items-center">
              <h2 className="text-center ">RESERVERBORD</h2>
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center pb-3">
              <h2 className="text-center">{name}</h2>
            </div>

            <div className="main-row d-flex justify-content-between pb-1 w-100">
              <FormGroup className="my-2 mb-sm-0 d-flex justify-content-between">
                <Label for="FIRSTNAME">
                  Fornavn <span className="text-danger">*</span>{" "}
                </Label>
                <Input
                  className={formError.firstName && "reserveInput"}
                  type="text"
                  name="firstName"
                  id="FIRSTNAME"
                  placeholder=""
                  value={formObject.firstName}
                  onChange={handleInputChange}
                />
                {formError.firstName && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.firstName}
                  </p>
                )}
              </FormGroup>

              <FormGroup className="my-2 mb-sm-0 d-flex justify-content-between">
                <Label for="LASTNAME">
                  Etternavn <span className="text-danger">*</span>
                </Label>
                <Input
                  className={formError.lastName && "reserveInput"}
                  type="text"
                  name="lastName"
                  id="LASTNAME"
                  placeholder=""
                  value={formObject.lastName}
                  onChange={handleInputChange}
                />
                {formError.lastName && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.lastName}
                  </p>
                )}
              </FormGroup>
            </div>

            <div className="main-row d-flex justify-content-between pb-1 pt-1 w-100">
              <FormGroup className="my-2 mb-sm-0 d-flex justify-content-between">
                <Label for="PHONENUMBER">
                  Telefon <span className="text-danger">*</span>
                </Label>
                <div>
                  <Input
                    type="select"
                    name="select"
                    id="selectField"
                    onChange={onCountryCodeSelectHandler}
                    defaultValue={"[BV]:47"}
                  >
                    {allCountryCodes.map((cn) => {
                      return <option value={cn}>{cn.split(":")[1]}</option>;
                    })}
                  </Input>
                  <Input
                    className={formError.phoneNumber && "reserveInput"}
                    type="text"
                    name="phoneNumber"
                    id="PHONENUMBER"
                    placeholder=""
                    value={formObject.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
                {formError.phoneNumber && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.phoneNumber}
                  </p>
                )}
              </FormGroup>

              <FormGroup className="my-2 mb-sm-0 d-flex justify-content-between">
                <Label for="emailId">
                  E-Post <span className="text-danger">*</span>
                </Label>
                <Input
                  className={formError.email && "reserveInput"}
                  type="text"
                  name="email"
                  id="emailId"
                  placeholder=""
                  value={formObject.email}
                  onChange={handleInputChange}
                />
                {formError.email && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.email}
                  </p>
                )}
              </FormGroup>
            </div>

            <div className="main-row d-flex justify-content-between pb-1 pt-1 w-100">
              <FormGroup className="my-2 mb-sm-0 d-flex justify-content-between">
                <Label for="exampleDate">
                  Dato <span className="text-danger">*</span>
                </Label>

                <Input
                  className={formError.date && "reserveInput"}
                  type="date"
                  name="date"
                  id="exampleDate"
                  placeholder=""
                  value={formObject.date}
                  onChange={handleInputChange}
                />
                {formError.date && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.date}
                  </p>
                )}
              </FormGroup>

              <FormGroup className="my-2 mb-sm-0 d-flex justify-content-between">
                <Label for="exampleTime">
                  Tid <span className="text-danger">*</span>
                </Label>
                <Input
                  className={formError.time && "reserveInput"}
                  type="time"
                  name="time"
                  id="exampleTime"
                  placeholder=""
                  value={formObject.time}
                  onChange={handleInputChange}
                />
                {formError.time && (
                  <p style={{ color: "red" }} className="errorMessage">
                    {formError.time}
                  </p>
                )}
              </FormGroup>
            </div>

            <div className="main-row d-flex pb-1 pt-1 w-100">
              <FormGroup className="my-2 mb-sm-0 w-100 d-flex justify-content-between">
                <Label for="Note">Merk</Label>
                <Input
                  type="textarea"
                  name="note"
                  id="Note"
                  value={formObject.note}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </div>

            <div className="w-100 d-flex justify-content-center align-items-center pt-4 pb-3 ">
              <Button type="submit" className="send-booking">
                Send Bestilling
              </Button>
            </div>
          </Form>
        </Col> */}



        </Row>
      )}

      {isVisible && (
        <Modal isOpen={isVisible}>
          <ModalHeader
            toggle={() => {
              setIsVisible(false);
            }}
          >
            Bekreft bestillingen
          </ModalHeader>
          <ModalBody>
            <p>
              Din reservasjons sum blir konvertert til et gavekort som du mottar
              kort tid etter betalingen er fullført.
            </p>
            <h4 className="text-center">{`Betal ${price} kr`}</h4>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center align-items-center">
            <Button
              color="primary"
              //disabled={!price}
              onClick={payWithTidyPay}
            >
              Pay
            </Button>
            {/*additional.vippsPermission && (
              <Button
                color="primary"
                disabled={!price}
                onClick={payWithVippsClicked}
              >
                Pay with Vipps
              </Button>
           )*/}
          </ModalFooter>
        </Modal>
      )}
      {loading && (
        <div className="example">
          <Spin />
        </div>
      )}
    </Container>
  );
};

export default ReserveTable
